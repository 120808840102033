import PropertyCharacteristcFilter from '@component/Filter/PropertyCharacteristcFilter'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useSetState from '@hooks/useSetState'
import { getPropertyCharacteristics, getPropertyCHARList } from '@api'
import { Loading } from 'ra-ui-materialui'
import { ConditionalFieldITF, PropertyCHARQuestionsITF } from '@/types/characteristic'
import { CustomI18NITF } from '@activate-inc/activate-ui/dist/types/common.types'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import { format } from 'date-fns'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    background: '#FFF',
    borderRadius: 16,
    padding: 24,
  },
  right: {
    borderRadius: 16,
    padding: 24,
    backgroundColor: '#F5F5F5',
    minWidth: 728,
    flex: 1,
  },
  building: {
    display: 'flex',
    borderRadius: 8,
    backgroundColor: '#fff',
    padding: '28px 24px',
    flexDirection: 'column',
  },
  buildingHeader: {
    display: 'flex',
    paddingBottom: 8,
  },
  buildingHeaderLeft: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  buildingHeaderRight: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  buildingTitle: {
    color: '#466CB5',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '30px',
  },
  buildingAddress: {
    color: '#466CB5',
    fontSize: 12,
  },
  date: {
    color: '#707070',
    fontSize: 12,
  },
  buildingItem: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #E8E8E8',
    paddingTop: '24px',
    marginTop: 16,
  },
  sectionName: {
    color: '#466CB5',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  buildingItemContent: {
    display: 'grid',
    marginTop: 16,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '8px 24px',
  },
  questionItem: {
    gap: 12,
    display: 'flex',
  },
  questionLabel: {
    lineClamp: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '50%',
    color: '#211F1F',
    fontSize: 14,
    fontWeight: 600,
  },
  questionValue: {
    lineClamp: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '50%',
    color: '#211F1F',
    fontSize: 14,
  },
}))

interface BuildingInfoProps {
  params: Record<string, string>
  gId: string
}

interface ResultITF {
  LogoFilename: string
  Zip: string
  AddressLine1: CustomI18NITF
  City: CustomI18NITF
  State: CustomI18NITF
  BuildingName: CustomI18NITF
  PropertyCHARQuestions: PropertyCHARQuestionsITF[]
  ConditionalFields: { [key: string]: ConditionalFieldITF }
}
interface CHARListITF {
  BuildingId: string
  BuildingName: CustomI18NITF
  Region: string
  PropertyCharacteristics: { [key: string]: string | number }
}
const CONFIG: Array<TableConfig<any>> = [
  { source: 'BuildingName.en', label: 'Building Name', width: 186 },
  { source: 'Region', label: 'Region', width: 100 },
  { source: 'PropertyCharacteristics.OwnershipFund', label: 'Ownership Fund', width: 155 },
  { source: 'PropertyCharacteristics.AcquisionPortfolio', label: 'Acquision Portfolio', width: 210 },
  { source: 'PropertyCharacteristics.PropertyClass', label: 'Property Class (A/B/C)', width: 210 },
  { source: 'PropertyCharacteristics.YearBuilt', label: 'Year Built', width: 110 },
  { source: 'PropertyCharacteristics.LastRenovated', label: 'Last Renovated', width: 160 },
  { source: 'PropertyCharacteristics.PropertyAge', label: 'Property Age', width: 133 },
  { source: 'PropertyCharacteristics.ManagementType', label: 'Management Type', width: 173 },
  { source: 'PropertyCharacteristics.MultiTenated', label: 'Is Multi- Tenated?(Y/N) ', width: 215 },
  { source: 'PropertyCharacteristics.MajorTenant', label: 'Major Tenant', width: 294 },
  { source: 'PropertyCharacteristics.MajorTenantIndustry', label: 'Major Tenant Industry', width: 286 },
  {
    source: 'PropertyCharacteristics.MajorTenantLED',
    label: 'Major Tenant LED',
    isDate: true,
    formatKey: 'keyboardDate',
    width: 170,
  },
  { source: 'PropertyCharacteristics.PropertyType', label: 'Property Type', width: 224 },
  { source: 'PropertyCharacteristics.TenantSpaceUse', label: 'Tenant Space Use', width: 220 },
]
const AdvancedInfo: React.FC<BuildingInfoProps> = ({ params, gId }) => {
  const classes = useStyles()
  const [data, setData] = useState<CHARListITF[]>()
  const getData = useCallback(() => {
    getPropertyCHARList<{ Buidings: CHARListITF[] }>(Object.assign({}, params, { gId })).then((res) => {
      setData(res.Buidings || [])
    })
  }, [params, gId])
  useEffect(() => {
    getData()
  }, [getData])
  return <TableList tableLayout="fixed" config={CONFIG} data={data} rowKey={(item, index) => item.BuildingId} />
}
const BuildingInfo: React.FC<BuildingInfoProps> = ({ params, gId }) => {
  const classes = useStyles()
  const [info, setInfo] = useState<ResultITF>()
  const { PropertyCHARQuestions, City, Zip, State, AddressLine1, LogoFilename, BuildingName } = info || {}
  const getData = useCallback(() => {
    if (!params.bId) return
    getPropertyCharacteristics<ResultITF>(Object.assign({}, params, { gId })).then((res) => {
      setInfo(res)
    })
  }, [params, gId])
  useEffect(() => {
    getData()
  }, [getData])
  if (!params || !params.bId) {
    return (
      <Box className={classes.building}>
        <Typography sx={{ textAlign: 'center', margin: '100px' }}>Empty</Typography>
      </Box>
    )
  }
  if (!info) {
    return (
      <Box className={classes.building}>
        <Loading />
      </Box>
    )
  }
  return (
    <Box className={classes.building}>
      <Box className={classes.buildingHeader}>
        <Box className={classes.buildingHeaderLeft}>
          <Typography className={classes.buildingTitle}>Property Characteristics - {BuildingName?.en}</Typography>
          <Typography className={classes.buildingAddress}>
            {AddressLine1?.en}, {City?.en}, {State?.en}, {Zip}
          </Typography>
        </Box>
        <Box className={classes.buildingHeaderRight}>
          <img src={LogoFilename} style={{ height: 36 }} alt="" />
          <Typography className={classes.date}>{format(new Date(), 'MMM dd. yyyy')}</Typography>
        </Box>
      </Box>
      {!PropertyCHARQuestions ||
        (PropertyCHARQuestions.length === 0 && (
          <Typography sx={{ textAlign: 'center', margin: '100px' }}>No Data</Typography>
        ))}
      {PropertyCHARQuestions?.map((item) => {
        return (
          <Box key={item.SectionName} className={classes.buildingItem}>
            <Typography className={classes.sectionName}>{item.SectionName}</Typography>
            <Box className={classes.buildingItemContent}>
              {item.Questions?.map((item1) => {
                let data = item1.Value
                if (data && item1.Name === 'MajorTenantLED') {
                  data = format(new Date(data), 'MM/dd/yyyy')
                }
                return (
                  <Box key={item1.Name} className={classes.questionItem}>
                    <Typography className={classes.questionLabel}>{item1.FieldText} : </Typography>
                    <Typography className={classes.questionValue}>{data || 'N/A'}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
const PropertyCharacteristicsIndex: React.FC = () => {
  const classes = useStyles()
  const { selectedGroupId: gId } = useSelector((state) => state.profile)
  const [data, setData] = useSetState({ params: {}, tabId: 0 })
  const { params, tabId } = data
  const onSearch = useCallback((params, tabId) => {
    setData({ params, tabId })
  }, [])
  return (
    <div className={classes.paper}>
      <Box className={classes.content}>
        <PropertyCharacteristcFilter gId={gId} onSearch={onSearch} />
        <Box className={classes.right}>
          {tabId === 0 && <BuildingInfo params={params} gId={gId} />}
          {tabId === 1 && <AdvancedInfo params={params} gId={gId} />}
        </Box>
      </Box>
    </div>
  )
}

export default PropertyCharacteristicsIndex
