import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Button, Modal } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import PanelSelection from '@component/EditPanel/PanelSelection'
import useSetState from '@hooks/useSetState'
import CustomTextField from '@component/Form/CustomTextField'
import { NOTE_CATEGORY } from '@constant/app'

const useStyles = makeStyles(() => ({
  modal: {
    width: 500,
    padding: 24,
    maxHeight: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
  inputLabel: {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, -8px) scale(0.75)',
    },
  },
}))
interface Props {
  onClose: () => void
  onSave: (data: any) => void
  title?: string
}

const NoteModal: React.FC<Props> = React.memo(({ onClose, onSave, title = 'Add New Note' }) => {
  const classes = useStyles()
  const [data, setData] = useSetState({ Note: '', Type: '' })
  const { Note, Type } = data

  const onSaveClick = () => {
    onSave({ Note, Type, CreatedDate: Date.now() })
  }

  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.modal}>
        <Typography variant="h5" sx={{ fontSize: '16px', marginBottom: '24px' }}>
          {title}
        </Typography>
        <PanelSelection
          label="Category"
          sx={{ marginTop: '20px' }}
          choices={NOTE_CATEGORY}
          inputLabelClass={classes.inputLabel}
          value={NOTE_CATEGORY.find((item) => item.value === Type)}
          onChange={(item) => setData({ Type: item?.value as string })}
          popupIcon={<ExpandMore />}
          width={'100%'}
          height={56}
        />
        <CustomTextField
          style={{ width: '100%' }}
          label="Note"
          value={Note}
          disabled={false}
          placeholder="Enter here..."
          name="Details"
          onInputChange={(value) => setData({ Note: value })}
          variant="outlined"
          multiline
        />
        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSaveClick} variant="contained" color="primary" disabled={!Note || !Type}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

export default NoteModal
