import React, { useEffect, useCallback, useState } from 'react'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import EditAndSave from '@component/Button/EditAndSave'
import { getPropertyCharacteristics, savePropertyCharacteristics } from '@api'
import DaySelect from '@component/DateSelect/DaySelect'
import SnackBar from '@component/SnackBar'
import { ConditionalFieldITF, PropertyCHARQuestionsITF } from '@/types/characteristic'

const useStyles = makeStyles(() => {
  return {
    container: {
      backgroundColor: '#fff',
      position: 'relative',
      fontFamily: 'Roboto',
    },
    item: {
      minWidth: 230,
      margin: '0 0px 24px',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '16px',
      marginBottom: 32,
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 6,
      minHeight: 56,
      border: '1px solid #BCBCBC',
    },
    title: {
      paddingBottom: 16,
      color: '000',
      fontWeight: 700,
    },
    text: {
      flex: 1,
      paddingLeft: 8,
      fontSize: 16,
      textAlign: 'left',
      color: '#211F1F',
    },
    textField: {
      height: 56,
      marginTop: 0,
    },
  }
})

interface Props {
  disabled?: boolean
  isEdit?: boolean
  onBack?: () => void
  gId: string
  bId: string
}

const PropertyCHAR = (props: Props) => {
  const { isEdit, disabled, gId, bId } = props
  const classes = useStyles()
  const [unknown, setUnknown] = useSetState({})
  const [state, setState] = useSetState<{
    PropertyCHARQuestions?: PropertyCHARQuestionsITF[]
    ConditionalFields?: { [key: string]: ConditionalFieldITF }
  }>({})
  const { PropertyCHARQuestions, ConditionalFields } = state
  const [onlyView, setOnlyView] = useState(isEdit)

  const onEdit = () => {
    setOnlyView(false)
  }
  const onSave = () => {
    savePropertyCharacteristics({ BuildingId: bId, PropertyCHARQuestions: PropertyCHARQuestions }).then(() => {
      SnackBar({ msg: 'Successfully saved', type: 'success' })
    })
  }
  const onClickBack = () => {
    setOnlyView(true)
  }

  const getData = useCallback(async () => {
    getPropertyCharacteristics<{
      PropertyCHARQuestions: PropertyCHARQuestionsITF[]
      ConditionalFields: { [key: string]: ConditionalFieldITF }
    }>({ gId, bId }).then((res) => {
      const params = {}
      const ConditionalFields = res.ConditionalFields
      for (const propertyCHARQuestion of res.PropertyCHARQuestions || []) {
        for (const question of propertyCHARQuestion.Questions || []) {
          if (question.Value && ConditionalFields[question.Name]) {
            const data = ConditionalFields[question.Name]
            Object.assign(params, { [data.Key]: question.Value === data.Value ? data : undefined })
            setUnknown(params)
          }
        }
      }
      setState({ PropertyCHARQuestions: res.PropertyCHARQuestions, ConditionalFields })
    })
  }, [gId, bId, setState])
  useEffect(() => {
    getData()
  }, [getData])

  return (
    <Box className={`${classes.container}`}>
      {PropertyCHARQuestions?.map((item) => {
        const { SectionName, Questions } = item
        return (
          <Box key={SectionName} className={classes.item}>
            <Typography className={classes.title}>{SectionName}</Typography>
            <Box className={classes.content}>
              {Questions.map((item1) => {
                const { FieldStyle, FieldText, Value, Options = [], Name } = item1
                if (unknown[Name]) {
                  return (
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.textField}
                      variant="outlined"
                      key={FieldText + 'unknown'}
                      disabled
                      label={FieldText}
                      value={unknown[Name].Text}
                    />
                  )
                }
                if (['Text', 'Number'].includes(FieldStyle)) {
                  return (
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      onChange={(e) => {
                        item1.Value = e.target.value
                      }}
                      key={FieldText}
                      disabled={disabled || onlyView}
                      type={FieldStyle === 'Number' ? 'number' : ''}
                      label={FieldText}
                      defaultValue={Value as string}
                    />
                  )
                }
                if (['DateSelection'].includes(FieldStyle)) {
                  return (
                    <DaySelect
                      disabled={disabled || onlyView}
                      className={classes.textField}
                      key={FieldText}
                      label={FieldText}
                      defaultValue={Value ? new Date(Value) : null}
                      onChange={(value: Date | null) => {
                        item1.Value = value ? new Date(value).getTime() : undefined
                      }}
                    />
                  )
                }
                if ('DropDownSelection' === FieldStyle) {
                  return (
                    <Autocomplete
                      key={FieldText}
                      disabled={disabled || onlyView}
                      options={Options}
                      onChange={(event, value) => {
                        if (value && ConditionalFields?.[Name]) {
                          const data = ConditionalFields[Name]
                          const q = Questions.find((q) => q.Name === data.Key)
                          if (q) {
                            q.Value = ''
                          }
                          setUnknown({ [data.Key]: value === data.Value ? data : undefined })
                        }
                        item1.Value = value || ''
                      }}
                      defaultValue={Value}
                      className={classes.textField}
                      renderInput={(params) => <TextField {...params} label={FieldText} />}
                    />
                  )
                }
                return (
                  <Button key={FieldText} className={classes.box} disabled>
                    <Typography className={classes.text}>{FieldText}</Typography>
                  </Button>
                )
              })}
            </Box>
          </Box>
        )
      })}
      {!disabled && <EditAndSave showSave={!onlyView} onCancel={onClickBack} onEdit={onEdit} onSave={onSave} />}
    </Box>
  )
}
export default React.memo(PropertyCHAR)
