import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import { PhysicalSpacesITF, ProgressITF } from '@/types/entity'
import { makeStyles } from '@mui/styles'
import uuid from 'react-uuid'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { PanelChoice } from '@/types/panel'
import { isEmpty } from '@utils/common'
import SnackBar from '@component/SnackBar'
import { Autocomplete } from '@mui/lab'
import TextField from '@mui/material/TextField'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import UploadContent from '@component/Modal/UploadContent'
import { FileITF } from '@/types/survey'
import { FileUploadOutlined } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  add: {
    marginTop: 20,
    alignSelf: 'flex-start',
  },
  select: {
    height: 64,
    // marginTop: 20,
  },
  save: {
    width: 90,
    marginTop: 20,
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  iconBtn: {
    padding: 0,
  },
  netType: {
    marginTop: 20,
  },
  inputRoot: {
    height: 58,
    caretColor: 'transparent',
    '&[class*="MuiOutlinedInput-root"]': {
      zIndex: 1,
    },
  },
  input: {
    opacity: '0 !important',
  },
}))
interface Props {
  otherAmenities?: string
  data?: PhysicalSpacesITF[]
  floorsFromPattern?: string[]
  onChangeData?: (data: PhysicalSpacesITF[], progress: ProgressITF) => void
  onSave?: (data: {
    PhysicalSpaces: PhysicalSpacesITF[]
    OtherAmenities?: string
    PhysicalSpacesProgress: ProgressITF
  }) => void
  gId?: string
  bId?: string
  isResponses?: boolean
}
interface PhysicalSpaceItemProps {
  data: PhysicalSpacesITF
  floorsFromPattern?: string[]
  index: number
  gId?: string
  bId?: string
  showError?: boolean
  isResponses?: boolean
  onChange?: (item: PhysicalSpacesITF, index: number) => void
}
const RENTAL_STATUS = [
  { label: 'YES', value: true },
  { label: 'NO', value: false },
]
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const PhysicalSpaceItem: React.FC<PhysicalSpaceItemProps> = ({
  data,
  index,
  onChange,
  showError,
  floorsFromPattern,
  gId,
  bId,
  isResponses,
}) => {
  const classes = useStyles()
  const [state, setState] = useState(data || {})
  const [showModal, setShowModal] = useState(false)
  const { Selected, FloorNumbers, QuantityPerFloor, AvailableForRental, DisplayText, Files, Note } = state
  const isHasFile = Array.isArray(Files) && Files.length > 0
  const onInputChange = useCallback(
    (value: string, name: string) => {
      setState((prevState) => {
        //@ts-ignore
        const cur = Object.assign({}, prevState, { [name]: value }) as PhysicalSpacesITF
        onChange?.(cur, index)
        return cur
      })
    },
    [onChange, index]
  )
  const onChangeOption = useCallback(
    (event: any, newValue: string[]) => {
      setState((prevState) => {
        const cur = Object.assign({}, prevState, { FloorNumbers: newValue.join(',') }) as PhysicalSpacesITF
        onChange?.(cur, index)
        return cur
      })
    },
    [index, onChange]
  )
  const onSave = useCallback((note: string, files: FileITF[]) => {
    setState((prevState) => {
      const cur = Object.assign({}, prevState, { Note: note, Files: files }) as PhysicalSpacesITF
      onChange?.(cur, index)
      return cur
    })
    setShowModal(false)
  }, [])
  const floorNumbers = useMemo(() => (FloorNumbers ? FloorNumbers.split(',') : []), [FloorNumbers])
  let btnText = isHasFile ? 'UPLOAD MORE' : 'CONTENT UPLOAD'
  if (isResponses) {
    btnText = 'DOWNLOAD'
  }
  return (
    <Grid container rowSpacing={0} columnSpacing={2} sx={{ alignItems: 'center' }}>
      <Grid item container alignItems="center" xs={2.5}>
        <span>{DisplayText?.en}</span>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          multiple
          id="checkboxes-weekly-day"
          options={floorsFromPattern || []}
          value={floorNumbers}
          onChange={onChangeOption}
          disableCloseOnSelect
          getOptionLabel={(label) => label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          style={{ minHeight: 58, marginTop: 20 }}
          classes={{
            inputRoot: classes.inputRoot,
          }}
          renderTags={(value) => {
            if (value?.length > 0)
              return (
                <div
                  style={{
                    position: 'absolute',
                    overflow: 'hidden',
                    left: 10,
                    right: 40,
                    color: '#4D4D4D',
                    zIndex: -1,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {value?.join(',')}
                </div>
              )
            return null
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Floor Numbers (Or "All")'
              InputProps={{
                ...params.InputProps,
                disabled: true,
              }}
              error={showError && Selected && !floorNumbers?.length}
              placeholder=""
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomTextField
          label="Quantity per Floor"
          name="QuantityPerFloor"
          fullWidth
          error={showError && Selected && isEmpty(QuantityPerFloor)}
          type="number"
          disabled={false}
          placeholder="Enter here"
          variant="outlined"
          value={QuantityPerFloor || ''}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={1.5}>
        <PanelSelection
          height={58}
          className={classes.netType}
          disableClearable={false}
          width="100%"
          label="Available for Rental"
          // @ts-ignore
          value={RENTAL_STATUS.find((item) => item.value === AvailableForRental)}
          // @ts-ignore
          choices={RENTAL_STATUS}
          onChange={(item) => onInputChange(item?.value as string, 'AvailableForRental')}
          getOptionLabel={(option: PanelChoice) => option?.label || ''}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          sx={{ width: '100%', marginTop: '20px', fontSize: 14 }}
          onClick={() => setShowModal(true)}
        >
          {btnText}
          <FileUploadOutlined sx={{ fontSize: '20px', marginLeft: '8px' }} />
        </Button>
      </Grid>
      {showModal && (
        <UploadContent
          onClose={() => setShowModal(false)}
          gId={gId}
          bId={bId}
          note={Note}
          files={Files}
          isResponses={isResponses}
          onSave={onSave}
        />
      )}
    </Grid>
  )
}
const calculate = (data) => {
  const Denominator = 1
  const Numerator = data.filter(
    (item) =>
      item.Selected &&
      item.AvailableForRental !== undefined &&
      item.Location &&
      item.FloorNumbers &&
      item.QuantityPerFloor
  ).length
  return { Numerator: Numerator > 0 ? 1 : 0, Denominator }
}
const PhysicalSpaces: React.FC<Props> = ({
  data = [],
  onChangeData,
  onSave,
  otherAmenities,
  floorsFromPattern,
  bId,
  gId,
  isResponses,
}) => {
  const classes = useStyles()
  const [showError, setShowError] = useState(false)
  const [state, setState] = useState(data.map((item) => ({ ...item, FrontItemKey: uuid() } as PhysicalSpacesITF)) || [])
  const [inputText, setInputText] = useState<string>(otherAmenities || '')
  const onChange = useCallback(
    (item: PhysicalSpacesITF, index: number) => {
      setState((prevState) => {
        const data = prevState || []
        data.splice(index, 1, item)
        onChangeData?.(data, calculate(data))
        return data
      })
    },
    [onChangeData]
  )
  const onChangeSelected = useCallback(
    (item: PhysicalSpacesITF, checked: boolean, index: number) => {
      const cur = Object.assign({}, item, { Selected: checked }) as PhysicalSpacesITF
      onChange?.(cur, index)
    },
    [onChange]
  )
  const onClick = useCallback(() => {
    const empty = state.filter((item) => item.Selected && isEmpty(item.FloorNumbers) && isEmpty(item.FloorNumbers))
    if (empty.length) {
      SnackBar({ msg: 'Please fill in the fields' })
      setShowError(true)
      return
    }
    onSave?.({ PhysicalSpaces: state, OtherAmenities: inputText, PhysicalSpacesProgress: calculate(state) })
  }, [onSave, state, inputText])
  const onInputAmenities = useCallback((value: string, name: string) => {
    setInputText(value)
  }, [])
  return (
    <Box className={classes.container}>
      <Typography style={{ fontWeight: 600, fontSize: 20 }}>{'Locations'}</Typography>
      <Grid container rowSpacing={0} columnSpacing={2}>
        {state?.map((item, index) => {
          const { Selected, DisplayText } = item
          return (
            <Grid item xs={3} alignItems="center" key={index}>
              <FormControlLabel
                checked={Selected}
                control={<Checkbox />}
                label={DisplayText?.en}
                className={classes.select}
                onChange={(e, checked) => onChangeSelected(item, checked, index)}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box style={{ borderBottom: '1px solid #E8E8E8', marginBottom: 24 }} />
      <Typography style={{ fontWeight: 600, fontSize: 20 }}>{'Floor Information'}</Typography>
      {state?.map((item, index) => {
        if (!item.Selected) return null
        return (
          <PhysicalSpaceItem
            data={item}
            gId={gId}
            bId={bId}
            isResponses={isResponses}
            key={item.FrontItemKey}
            index={index}
            floorsFromPattern={floorsFromPattern}
            onChange={onChange}
            showError={showError}
          />
        )
      })}
      <CustomTextField
        value={inputText}
        variant="outlined"
        sx={{ width: '30%' }}
        label="Other Amenities"
        onInputChange={onInputAmenities}
        disabled={false}
      />
      <Button variant="contained" classes={{ contained: classes.save }} onClick={onClick}>
        Save
      </Button>
    </Box>
  )
}

export default PhysicalSpaces
