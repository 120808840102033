import React, { useEffect, useMemo, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSelector, useDispatch } from 'react-redux'
import { selectedProperty } from '@reducer/profile'
import { getPropertyDetail, createProperty, editProperty, savePins } from '@api'
import { useLocaleState } from 'react-admin'
import PropertyInformation, { PROPERTY_I18NAME } from './PropertyInformation'
import PropertyPIN from './PropertyPIN'
import PropertyBranding from './PropertyBranding'
import PropertyContent from './PropertyContent'
import PropertyContacts from './PropertyContacts'
import PropertyProduct from './PropertyProduct'
import PropertyTerms from './PropertyTerms'
import PropertyCOI from './PropertyCOI'
import useSetState from '@/hooks/useSetState'
import SnackBar from '@component/SnackBar'
import { canSaveTimeRange } from '@utils/DateUtil'
import { getPermissions } from '@common/auth'
import CustomerAccordion from '@component/Accordion/CustomerAccordion'
import { isValidPostalCode } from '@utils/helpers'
import CONFIG from '@constant/config'
import { copyText } from '@/utils'
import UpsellInfo from '@/account/UpsellInfo'
import PropertyCHAR from '@/account/PropertyCHAR'
import { PropertyInfo } from '@/types/entity'

const useStyles = makeStyles(() => {
  return {
    root: {
      minHeight: '100vh',
      padding: 16,
      flexDirection: 'column',
      width: '100%',
      backgroundColor: '#F7F7F7',
      textTransform: 'none',
      color: '#374053',
    },
    backIcon: {
      fontSize: 20,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,
    },
    propertyInfo: {
      marginLeft: -12,
      marginRight: -12,
    },
    box: {
      backgroundColor: '#fff',
      boxShadow: '0px 3px 2px #00000029',
      borderRadius: '0px 0px 4px 4px',
      padding: '24px 16px 16px',
      overflow: 'hidden',
      position: 'relative',
    },
    link: {
      backgroundColor: '#F5F5F5',
      borderRadius: 4,
      border: '1px solid #BCBCBC',
      padding: '12px 16px',
      color: '#211F1F',
      fontSize: 16,
      flex: 1,
      margin: '0px 16px 0 0',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
    },
    dataItem: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      fontFamily: 'Roboto, Regular',
      paddingTop: 10,
      paddingBottom: 10,
      width: '100%',
      marginTop: '1%',
      wordBreak: 'break-all',
    },
  }
})

const PropertyAdd = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [locale] = useLocaleState()
  const dispatch = useDispatch()
  const { selectedGroupName, selectedGroupId } = useSelector((state) => state.profile)
  const { GlobalManageEntities, ManagePin, ViewSuppress } = getPermissions()
  const { bId, idx: idxStr } = useParams<Record<string, string>>()
  const idx = useMemo(() => Number(idxStr || 0), [idxStr])
  const informationRef = useRef({
    informationData: [] as any[],
    pinData: [],
    contentData: [],
    contactsData: [],
    productData: [],
    getData: () => {},
    getTermsData: () => {},
    getCOIData: () => {},
  })

  const [state, setState] = useSetState({
    validate: false,
    isEdit: !!bId,
    showPropertyCHAR: false,
    editData: { AccountName: { en: selectedGroupName, es: selectedGroupName } },
  })
  const { validate, isEdit, editData, showPropertyCHAR } = state

  const onBack = () => {
    navigate(-1)
  }

  const onSave = async (currentIdx: number) => {
    setState({ validate: true })
    const {
      informationData = [],
      pinData = [],
      contentData = [],
      contactsData = [],
      productData = [],
      getData,
      getTermsData,
      getCOIData,
    } = informationRef.current

    let canSave = true
    let payloadData: any[] = []
    let payload: Record<string, any> = {}

    if (currentIdx === 0) {
      payloadData = informationData
      let timeRange: string[][] = []
      const Country = informationData.find((v: any) => v.name === 'Country')?.value as string
      const buildingTypeValue = informationData.find((v) => v.name === 'PropertyType')?.value

      informationData.forEach((v: any) => {
        if (v.required && !v.value) {
          canSave = false
        }
        if (v.name === 'Shifts') {
          timeRange = v.value?.map((v) => [v.StartTime, v.EndTime])
        }
        if (v.name === 'Zip' && !isValidPostalCode(v.value, Country)) {
          canSave = false
        }
        if (v.name === 'PropertyTag' && buildingTypeValue === 'Multiple' && !v.value) {
          canSave = false
        }
      })

      if (timeRange.flat().some((v) => v)) {
        if (timeRange.flat().includes('')) {
          return SnackBar({ msg: 'Please fill in the Shift fields', duration: 3000 })
        }
        if (!canSaveTimeRange(timeRange)) {
          return SnackBar({
            // eslint-disable-next-line prettier/prettier, quotes
            msg: "Can't overlap; should cover all 24 hours; Each shift cannot be less than 60 minutes",
            duration: 3000,
          })
        }
      }
    }

    if (currentIdx === 1) {
      // @ts-ignore
      payloadData = [{ name: 'PinCodes', value: pinData?.map((item) => ({ Type: item.name, Code: item.value })) }]
      pinData.forEach((v: any) => {
        if (v.required && !v.value) {
          canSave = false
        }
      })
    }

    if (currentIdx === 2) {
      // @ts-ignore
      const brandingData = (await getData?.()) || {}
      payload = Object.assign(payload, brandingData)
    }
    if (currentIdx === 3) {
      payloadData = contentData
    }
    if (currentIdx === 4) {
      payload['Contacts'] = contactsData
    }
    if (currentIdx === 5) {
      productData.map((v: any) => {
        payload[v.checkedName] = v.checked
        if (!v.disabled) {
          payload[v.urlName] = v.checked ? v.urlValue : ''
        }
      })
    }
    if (currentIdx === 7) {
      // @ts-ignore
      const brandingData = (await getTermsData?.()) || {}
      payload = Object.assign(payload, brandingData)
    }

    if (currentIdx === 8) {
      // @ts-ignore
      const COI = (await getCOIData?.()) || {}
      payload = Object.assign(payload, { COI })
    }

    if (!canSave) {
      return SnackBar({ msg: 'Please fill in the required fields', type: 'error', duration: 3000 })
    }

    payloadData.forEach((v: any) => {
      const { name, value } = v
      payload[name] = value
      if (PROPERTY_I18NAME.includes(name)) {
        payload[name] = { en: value, es: value }
      }
      if (name === 'PropertyTag') {
        payload[name] = v.PropertyTagId
          ? {
              PropertyTagId: v.PropertyTagId,
              Name: value,
            }
          : {}
      }
    })

    if (isEdit) {
      payload['EntityId'] = bId
      payload['BuildingId'] = bId
    }
    let api = isEdit ? editProperty : createProperty
    if (isEdit && currentIdx === 1) {
      api = savePins
    }
    api({ GroupId: selectedGroupId, ...payload })
      .then((res: any) => {
        setState({ showPropertyCHAR: res.BuildingType === 'Industrial' })
        if (currentIdx !== 1) {
          const buildingCode = res.Code
          dispatch(
            selectedProperty({
              propertyId: res?.acId,
              propertyName: res.Name[locale],
              buildingCode,
              isCreateBuilding: true,
              propertyTag: res.PropertyTag || {},
            })
          )
        }

        if (isEdit) {
          SnackBar({ msg: 'Successfully saved!', type: 'success' })
        } else {
          navigate('/propertyQrGenerator')
        }
      })
      .catch((err) => {
        SnackBar({ msg: err, type: 'error', duration: 3000 })
      })
  }

  useEffect(() => {
    if (bId) {
      getPropertyDetail<PropertyInfo>({ eId: bId }).then((res) => {
        setState({ isEdit: true, editData: res as any, showPropertyCHAR: res?.BuildingType === 'Industrial' })
      })
    }
  }, [bId, setState])
  const CustomerSurveyLinks = useMemo(
    () => [
      { label: 'Basic Info Survey', link: `${CONFIG.adminHost}/surveyWelcome/contentSurvey/${bId}` },
      {
        label: 'Connect Content Survey',
        link: `${CONFIG.adminHost}/surveyWelcome/contentCollection/${bId}`,
      },
      { label: 'Verify Content Survey', link: `${CONFIG.adminHost}/surveyWelcome/verifySurvey/${bId}` },
      { label: 'Digital Proof', link: `${CONFIG.adminHost}/surveyWelcome/digitalProofCarousel/${bId}` },
      { label: 'Sign proof', link: `${CONFIG.adminHost}/surveyWelcome/signProof/${bId}` },
    ],
    [bId]
  )
  console.log('editDataeditData', showPropertyCHAR)
  return (
    <Box className={classes.root}>
      <Box className={classes.backIcon} onClick={onBack}>
        <ArrowBackIcon style={{ marginRight: 10 }} />
        Back
      </Box>
      <CustomerAccordion title="Basic Info" contentClass={classes.propertyInfo} defaultExpanded={idx === 0}>
        <PropertyInformation
          disabled={!GlobalManageEntities}
          validate={validate}
          informationRef={informationRef}
          isEdit={isEdit}
          editData={editData}
          onSave={() => onSave(0)}
          isPropertyAdd={true}
          gId={selectedGroupId}
        />
      </CustomerAccordion>
      <CustomerAccordion title="Building PIN" contentClass={classes.propertyInfo} defaultExpanded={idx === 1}>
        <PropertyPIN
          disabled={!(GlobalManageEntities || ManagePin)}
          validate={validate}
          informationRef={informationRef}
          isEdit={isEdit}
          editData={editData}
          onSave={() => onSave(1)}
        />
      </CustomerAccordion>
      <CustomerAccordion title="Branding" defaultExpanded={idx === 2}>
        <PropertyBranding
          gId={selectedGroupId}
          informationRef={informationRef}
          isEdit={isEdit}
          disabled={!GlobalManageEntities}
          editData={editData}
          onSave={() => onSave(2)}
        />
      </CustomerAccordion>

      <CustomerAccordion
        title="Content"
        contentClass={classes.propertyInfo}
        disabled={!ViewSuppress}
        defaultExpanded={idx === 3}
      >
        <PropertyContent informationRef={informationRef} isEdit={isEdit} editData={editData} onSave={() => onSave(3)} />
      </CustomerAccordion>

      {!isEdit && (
        <CustomerAccordion title="Building Contacts" defaultExpanded={idx === 4}>
          <PropertyContacts
            informationRef={informationRef}
            isEdit={isEdit}
            editData={editData}
            onSave={() => onSave(4)}
          />
        </CustomerAccordion>
      )}

      <CustomerAccordion title="Product Add-Ons" disabled={!ViewSuppress} defaultExpanded={idx === 5}>
        <PropertyProduct
          informationRef={informationRef}
          isEdit={isEdit}
          editData={editData}
          onSave={() => onSave(5)}
          gId={selectedGroupId}
        />
      </CustomerAccordion>
      {!!bId && (
        <CustomerAccordion title="Customer Survey Links" disabled={!ViewSuppress} defaultExpanded={idx === 5}>
          <Box className={classes.box}>
            <Box className={classes.dataItem}>
              {CustomerSurveyLinks.map((v, i) => {
                return (
                  <Box key={v.label} style={{ width: '50%', paddingRight: 32, marginBottom: 32 }}>
                    <Typography style={{ color: '#374053', fontSize: 16, marginBottom: 8 }}>{v.label}</Typography>
                    <Box style={{ display: 'flex' }}>
                      <Typography className={classes.link}>{v.link}</Typography>
                      <Button variant="outlined" onClick={() => copyText(v.link)}>
                        COPY
                      </Button>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </CustomerAccordion>
      )}

      <CustomerAccordion title="Building Terms & Conditions" defaultExpanded={idx === 7}>
        <PropertyTerms
          gId={selectedGroupId}
          informationRef={informationRef}
          isEdit={isEdit}
          disabled={!GlobalManageEntities}
          editData={editData}
          onSave={() => onSave(7)}
        />
      </CustomerAccordion>

      <CustomerAccordion title="COI Information" defaultExpanded={idx === 8}>
        <PropertyCOI
          informationRef={informationRef}
          isEdit={isEdit}
          disabled={!GlobalManageEntities}
          editData={editData}
          onSave={() => onSave(8)}
        />
      </CustomerAccordion>
      {!!bId && (
        <CustomerAccordion title="Upsells" defaultExpanded={idx === 9}>
          <UpsellInfo isEdit={isEdit} gId={selectedGroupId} bId={bId} />
        </CustomerAccordion>
      )}
      {!!bId && showPropertyCHAR && (
        <CustomerAccordion title="Property Characteristics" defaultExpanded={idx === 10}>
          <PropertyCHAR isEdit={isEdit} gId={selectedGroupId} bId={bId} />
        </CustomerAccordion>
      )}
    </Box>
  )
}
export default PropertyAdd
