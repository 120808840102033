import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import { Box, Button, Tooltip } from '@mui/material'
import { ACTIVATE_COMPANY_LOGO_WHITE } from '@/global'
import { LoadingPage, Notification, useRedirect } from 'react-admin'
import { makeStyles } from '@mui/styles'
import { ArrowBack, CheckCircle, ErrorOutline, TaskAlt } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import LoginInput from '@component/Auth/LoginInput'
import PersonOutline from '@mui/icons-material/PersonOutline'
import LockOutlined from '@mui/icons-material/LockOutlined'
import EmailIcon from '@mui/icons-material/Email'
import { isEmail, isPasswordValid } from '@utils/helpers'
import { forgetPassword, resetPassword } from '@api'
import SnackBar from '@component/SnackBar'
import { useNavigate, useSearchParams } from 'react-router-dom'
import classnames from 'classnames'
import { removeAuthToken, setAuthToken } from '@common/auth'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import TextField from '@mui/material/TextField'
import { PASSWORD_TIPS } from '@constant/app'
import useSetState from '@hooks/useSetState'

const useStyles = makeStyles((theme) => ({
  leftBg: {
    background: 'transparent linear-gradient(132deg, #4268B3 0%, #1B365D 100%) 0% 0% no-repeat padding-box',
    justifyContent: 'center',
  },
  main: {
    minHeight: '100vh',
  },
  leftPanel: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // backgroundColor: 'red',
    // padding: '172px 135px 0',
  },
  back: {
    marginTop: 26,
    marginLeft: 16,
    color: '#374053',
  },
  backIcon: {
    marginRight: 8,
    borderRadius: 12,
    backgroundColor: '#386BBF26',
  },
  lockIcon: {
    height: 66,
    width: 66,
    padding: 16,
    borderRadius: 33,
    backgroundColor: '#386BBF26',
    marginTop: 107,
  },
  title: {
    fontWeight: '500',
    fontSize: 30,
    color: '#414141',
  },
  subTitle: {
    color: '#949494',
    marginTop: 10,
    fontSize: 12,
  },
  email: {
    color: '#000000',
    marginTop: 4,
    fontSize: 12,
  },
  input: {
    marginTop: 48,
  },
  btn: {
    textTransform: 'none',
    backgroundColor: '#4268B3',
    marginTop: 41,
  },
  btnEmail: {
    marginTop: 48,
  },
  content: {
    textAlign: 'center',
    padding: '0 135px 0',
  },
  checkEmail: {
    marginTop: 32,
    fontSize: 14,
    color: '#4268B3',
  },
  resent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
    fontSize: 12,
    color: '#949494',
  },
  resentTheme: {
    textIndent: 8,
    color: '#386BBF',
    cursor: 'pointer',
  },
  rootLabel: {
    color: theme.palette.grey[600],
    lineHeight: 1,
  },
  inputRoot: {
    height: 48,
  },
  notchedOutline: {
    borderColor: '#C4C4C4',
  },
  passwordWraper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    marginTop: 24,
  },
  tipicon: {
    position: 'absolute',
    right: -36,
    top: 'calc(50% - 12px)',
  },
  helpText: {
    marginBottom: 24,
    marginTop: 8,
    minHeight: 18,
    textAlign: 'left',
    fontSize: 12,
    color: '#949494',
  },
  tipContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tipItem: {
    display: 'flex',
    // alignItems: 'center'
  },
  tipDot: {
    marginTop: 8,
    width: 4,
    height: 4,
    backgroundColor: 'white',
    borderRadius: 2,
    marginRight: 4,
  },
  tipText: {
    color: 'white',
    fontSize: 10,
    flex: 1,
  },
  resetedIcon: {
    fontSize: 60,
    marginTop: 300,
  },
  reseted: {
    marginTop: 32,
    color: '#414141',
    fontSize: 20,
  },
  error: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 40,
    textAlign: 'center',
  },
}))

enum Type {
  input,
  resend,
  reset,
}

const ResetEmail = () => {
  const classes = useStyles()
  const [email, setEmail] = useState()
  const [type, setType] = useState<Type>()
  const onChangeEmail = useCallback(
    (event) => {
      setEmail(event.target.value)
    },
    [setEmail]
  )
  const onClickSubmit = useCallback(async () => {
    try {
      await forgetPassword({ Email: email })
      setType(Type.resend)
      SnackBar({ msg: 'Successful', type: 'success' })
    } catch (e) {
      SnackBar({ msg: e })
    }
  }, [email])
  const onReSend = useCallback(() => {
    onClickSubmit()
  }, [onClickSubmit])
  useEffect(() => {}, [])
  const disabled = !isEmail(email)
  const isInput = type !== Type.resend
  const ICON = isInput ? LockOutlined : EmailIcon
  return (
    <div className={classes.content}>
      <ICON htmlColor="#386BBF" className={classes.lockIcon} />
      <Typography component="h1" variant="h5" className={classes.title}>
        {isInput ? 'Forgot Password?' : 'Check your Email'}
      </Typography>
      <Typography className={classes.subTitle}>
        {isInput ? "No Worries, We'll send you reset instructions" : 'We sent a password reset link to'}
      </Typography>
      {!isInput && <Typography className={classes.email}>{email}</Typography>}
      {isInput && (
        <LoginInput
          id="email"
          className={classes.input}
          placeholder="Email Address"
          name="email"
          onChange={onChangeEmail}
          icon={<PersonOutline htmlColor="#fff" />}
          value={email}
          autoComplete="off"
        />
      )}
      {isInput ? (
        <Button
          disabled={disabled}
          onClick={onClickSubmit}
          fullWidth
          color="primary"
          href={isInput ? undefined : `mailto:${email}`}
          classes={{
            root: classnames(classes.btn, { [classes.btnEmail]: !isInput }),
          }}
          variant="contained"
        >
          Reset Password
        </Button>
      ) : (
        <Typography className={classes.checkEmail}>Please check your email</Typography>
      )}
      {!isInput && (
        <Box className={classes.resent}>
          {"Didn't receive the email?"}
          <div className={classes.resentTheme} onClick={onReSend}>
            Click to resend
          </div>
        </Box>
      )}
    </div>
  )
}

const TextFieldItem: React.FC<
  {
    onChange: (value: string) => void
  } & TextFieldProps
> = (props) => {
  const [value, setValue] = useState('')

  const classes = useStyles({ value })

  const onChangeValue = useCallback(
    (event) => {
      setValue(event.target.value)
      props.onChange?.(event)
    },
    [setValue]
  )

  useEffect(() => {
    setValue((props.value as string) || '')
  }, [props.value])

  return (
    <TextField
      {...props}
      value={value}
      onChange={onChangeValue}
      fullWidth
      variant="outlined"
      InputLabelProps={{
        classes: {
          root: classes.rootLabel,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          notchedOutline: classes.notchedOutline,
        },
      }}
    />
  )
}
const ResetPassword: React.FC<{ email?: string }> = ({ email }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [data, setData] = useSetState<{ password?: string; confirmPassword?: string; reseted?: boolean }>({})
  const { password, confirmPassword, reseted } = data || {}
  const onClickSubmit = useCallback(async () => {
    try {
      await resetPassword({ Password: password })
      setData({ reseted: true })
      removeAuthToken()
      setTimeout(() => {
        navigate('/login', { replace: true })
      }, 2000)
    } catch (e) {
      SnackBar({ msg: e })
    }
  }, [password])
  const { msg, checked } = isPasswordValid(password)
  const onChangePassword = useCallback((event) => {
    setData({ [event.target.name]: event.target.value })
  }, [])
  let helpText = msg
  if (checked && confirmPassword && password !== confirmPassword) {
    helpText = 'Password confirmation does not match'
  }
  if (reseted) {
    return (
      <div className={classes.content}>
        <CheckCircle htmlColor="#35AA2D" className={classes.resetedIcon} />
        <Typography className={classes.reseted}>Your new password is saved!</Typography>
      </div>
    )
  }
  return (
    <div className={classes.content}>
      {email ? (
        <>
          <Typography sx={{ fontSize: '38px', fontWeight: '700', marginTop: '107px', color: '#466CB5' }}>
            Welcome!
          </Typography>
          <Typography sx={{ fontSize: '14px', color: '#707070', minWidth: '385px' }}>
            Please enter your details.
          </Typography>
          <TextFieldItem
            name="email"
            label="Name"
            type="text"
            value={email}
            disabled
            onChange={() => {}}
            sx={{ marginTop: '48px' }}
          />
        </>
      ) : (
        <>
          <LockOutlined htmlColor="#386BBF" className={classes.lockIcon} />
          <Typography component="h1" variant="h5" className={classes.title}>
            Set new password
          </Typography>
          <Typography className={classes.subTitle}>
            {'Your new password must be different from \npreviously used passwords.'}
          </Typography>
        </>
      )}
      <div className={classes.passwordWraper}>
        <TextFieldItem
          name="password"
          label="Password"
          type="password"
          value={password}
          onChange={onChangePassword}
          autoComplete="new-password"
        />
        {checked ? (
          <TaskAlt htmlColor="#35AA2D" className={classes.tipicon} />
        ) : (
          <Tooltip
            title={
              <div className={classes.tipContainer}>
                {PASSWORD_TIPS.map((item, i) => {
                  return (
                    <div className={classes.tipItem} key={i}>
                      <div className={classes.tipDot} />
                      <Typography className={classes.tipText}>{item}</Typography>
                    </div>
                  )
                })}
              </div>
            }
            arrow
          >
            <ErrorOutline htmlColor="#5A5151" className={classes.tipicon} />
          </Tooltip>
        )}
      </div>
      <Typography className={classes.helpText}>{helpText}</Typography>
      <div className={classes.passwordWraper}>
        <TextFieldItem
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={onChangePassword}
          autoComplete="new-password"
        />
        {checked && password === confirmPassword && <TaskAlt htmlColor="#35AA2D" className={classes.tipicon} />}
      </div>

      <Button
        onClick={onClickSubmit}
        fullWidth
        disabled={!checked || password !== confirmPassword}
        color="primary"
        classes={{
          root: classnames(classes.btn),
        }}
        variant="contained"
      >
        {email ? 'Confirm' : 'Reset Password'}
      </Button>
    </div>
  )
}
const Reset = () => {
  const classes = useStyles()
  const [search] = useSearchParams()
  const token = useMemo(() => search.get('token'), [search])
  const email = useMemo(() => search.get('email') || '', [search])
  const [type, setType] = useState(token ? Type.reset : Type.input)
  const [loading, setLoading] = useState(!!token)
  const [errMsg, setErrMsg] = useState<string>()
  const redirectTo = useRedirect()
  const showBack = !email
  const fetchStatus = useCallback(async () => {
    try {
      await resetPassword({})
      setLoading(false)
    } catch (e) {
      setErrMsg(e as string)
      setLoading(false)
    }
  }, [])
  const onBack = useCallback(() => {
    redirectTo('/login')
  }, [redirectTo])
  useEffect(() => {
    setAuthToken(token)
    if (token) {
      fetchStatus()
    }
  }, [token])
  if (loading) {
    return <LoadingPage />
  }
  if (errMsg) {
    return <Typography className={classes.error}>{errMsg}</Typography>
  }
  return (
    <Grid container className={classes.main}>
      <Hidden only={['xs', 'sm']}>
        <Grid item xs={6} className={classes.leftBg}>
          <Box className={classes.leftPanel}>
            <img id="loginicon" alt="loginlogo" src={ACTIVATE_COMPANY_LOGO_WHITE} width={600} />
          </Box>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6} className={classes.rightForm}>
        {showBack && (
          <Button className={classes.back} onClick={onBack}>
            <ArrowBack htmlColor="#386BBF" className={classes.backIcon} />
            Back to Login
          </Button>
        )}
        {(type === Type.input || type === Type.resend) && <ResetEmail />}
        {type === Type.reset && <ResetPassword email={email} />}
      </Grid>
      <Notification />
    </Grid>
  )
}

export default Reset
