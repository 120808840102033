import { Box, Tab, Tabs, Button } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { TabPanel } from '@component/Tabs/TabPanel'
import MultipleSelect from '@component/Filter/MultipleSelect'
import { getPropertyCHARFilter } from '@api'
import { BuildingITF, PropertyFilterITF, QuestionITF } from '@/types/characteristic'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#fff',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    paddingRight: 24,
  },
  tabSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    background: '#fff',
    borderBottom: '1px solid #BCBCBC',
    marginBottom: 16,
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: 'Roboto',
    height: 20,
    lineHeight: 20,
    borderRadius: 4,
    opacity: '1',
  },
  multiple: {
    minWidth: 220,
    marginBottom: 24,
    flex: 1,
  },
}))

const CONFIG_KEY = { label: 'Region', value: 'Region' }
const CONFIG_KEY_BUILDING = { label: 'Name.en', value: 'BuildingId' }
const CONFIG_KEY_ADVANCED = { label: 'FieldText', value: 'Name' }

interface Props {
  gId: string
  onSearch?: (params: Record<string, string>, type: number) => void
}
const PropertyCharacteristcFilter: React.FC<Props> = ({ gId, onSearch }) => {
  const classes = useStyles()
  const [tabId, setTabId] = useState(0)
  const [region, setRegion] = useState()
  const [building, setBuilding] = useState<BuildingITF>()
  const [filter, setFilter] = useState<PropertyFilterITF>()
  const [advanced, setAdvanced] = useState<{ [key: string]: QuestionITF[] }>({})
  const getData = useCallback(() => {
    getPropertyCHARFilter<PropertyFilterITF>({ gId }).then((res) => {
      setFilter(res)
    })
  }, [gId])
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabId(newValue)
    if (tabId) {
      setAdvanced({})
    } else {
      setRegion(undefined)
      setBuilding(undefined)
    }
  }
  const onChangeRegion = (value) => {
    setRegion(value[0])
  }
  const onChangeAdvanced = (value, key) => {
    Object.assign(advanced, { [key]: value })
    setAdvanced(advanced)
  }
  const onChangeBuilding = (value) => {
    setBuilding(value?.[0])
  }
  const onClickSearch = (type: number) => {
    const params = {}
    if (type === 0) {
      Object.assign(params, { bId: building?.BuildingId })
    } else {
      const advanceds: Array<string> = []
      advanced &&
        Object.values(advanced).forEach((item) => {
          item.forEach((item1) => {
            advanceds.push(item1.Name)
          })
        })
      Object.assign(params, { fields: JSON.stringify(advanceds) })
    }
    onSearch?.(params, tabId)
  }
  useEffect(() => {
    getData()
  }, [getData])
  return (
    <Box className={classes.container}>
      <Tabs value={tabId} onChange={handleTabChange} className={classes.tabSection}>
        <Tab label="Search By Building" value={0} className={classes.tabLabel} />
        <Tab label="Advanced Search" value={1} className={classes.tabLabel} />
      </Tabs>
      <TabPanel value={tabId} index={0} sx={{ textAlign: 'end', padding: 0 }}>
        <MultipleSelect
          // @ts-ignore
          data={filter?.Regions || []}
          label="Region"
          itemKey={CONFIG_KEY}
          // defaultValue={item.Buttons}
          onChange={onChangeRegion}
          className={classes.multiple}
        />
        <MultipleSelect
          // @ts-ignore
          key={region?.Region}
          // @ts-ignore
          data={region?.Buildings || []}
          label="Building Name"
          itemKey={CONFIG_KEY_BUILDING}
          onChange={onChangeBuilding}
          className={classes.multiple}
        />
        <Button
          disabled={!building}
          variant="contained"
          sx={{ marginTop: '8px' }}
          onClick={() => {
            onClickSearch(0)
          }}
        >
          Search
        </Button>
      </TabPanel>
      <TabPanel value={tabId} index={1} sx={{ textAlign: 'end', padding: 0 }}>
        {filter?.Questions?.map((item) => {
          return (
            <MultipleSelect
              key={item.SectionName}
              isMulti
              // @ts-ignore
              data={item?.Questions || []}
              label={item.SectionName}
              itemKey={CONFIG_KEY_ADVANCED}
              onChange={(value) => onChangeAdvanced(value, item.SectionName)}
              className={classes.multiple}
            />
          )
        })}
        <Button
          variant="contained"
          sx={{ marginTop: '8px' }}
          onClick={() => {
            onClickSearch(1)
          }}
        >
          Search
        </Button>
      </TabPanel>
    </Box>
  )
}
export default PropertyCharacteristcFilter
