import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Button, Modal } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import PanelSelection from '@component/EditPanel/PanelSelection'
import useSetState from '@hooks/useSetState'
import CustomTextField from '@component/Form/CustomTextField'
import { NOTE_CATEGORY, REQUEST_TYPE_CHOICE } from '@constant/app'
import { getBuildingActiveQrHubs, saveCarouselNote, savePrintProofNote } from '@api'
import { EditPanelITF, PanelChoice } from '@/types/panel'

const useStyles = makeStyles(() => ({
  modal: {
    width: 500,
    padding: 24,
    maxHeight: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
  inputLabel: {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, -8px) scale(0.75)',
    },
  },
  box: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,
  },
}))
interface Props {
  onClose?: (success: boolean) => void
  title?: string
  gId?: string
  bId?: string
  type: string
}

interface StateITF {
  Note: string
  Category: string
  RequestType: string
  hubs?: PanelChoice[]
  selectHub?: PanelChoice | null
  selectItem?: PanelChoice | null
  items?: PanelChoice[]
}
const AddNoteModal: React.FC<Props> = React.memo(({ onClose, title = 'Add New Note', gId, bId, type }) => {
  const classes = useStyles()
  const [data, setData] = useSetState<StateITF>({ Note: '', Category: '', RequestType: '' })
  const { Note, Category, RequestType, hubs, selectHub, items, selectItem } = data
  const disabled = !Note || !Category || !RequestType || !selectHub || !selectItem

  const onSaveClick = async () => {
    const api = type === 'ContentProof' ? saveCarouselNote : savePrintProofNote
    const params = {
      GroupId: gId,
      BuildingId: bId,
      Note,
      Category,
      RequestType,
      ButtonName: selectItem?.label,
      QrName: selectHub?.label,
      SourceType: 'Offline',
    }
    await api(params)
    onClose?.(true)
  }
  const getHubs = useCallback(async () => {
    const result = await getBuildingActiveQrHubs<{ Hubs: EditPanelITF[] }>({ gId, bId })
    const hubs = result.Hubs.map((item) => {
      return {
        label: item.Name,
        value: item.Id,
        items: (item.Items || []).map((item1) => {
          return {
            label: item1.Name,
            value: item1.Id,
          } as PanelChoice
        }),
      } as PanelChoice
    })
    setData({ hubs, items: [], selectItem: undefined, selectHub: undefined })
  }, [gId, bId])
  useEffect(() => {
    getHubs()
  }, [getHubs])
  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.modal}>
        <Typography variant="h5" sx={{ fontSize: '16px', marginBottom: '24px' }}>
          {title}
        </Typography>
        <Box className={classes.box}>
          <PanelSelection
            label="Hub"
            choices={hubs || []}
            inputLabelClass={classes.inputLabel}
            value={(hubs || []).find((item) => item.value === selectHub?.value)}
            onChange={(item) => setData({ selectHub: item })}
            popupIcon={<ExpandMore />}
            width={'100%'}
            height={56}
          />
          <PanelSelection
            height={56}
            label="Page"
            inputLabelClass={classes.inputLabel}
            width={'100%'}
            value={(selectHub?.items || [])?.find((item1) => item1.value === selectItem?.value)}
            choices={selectHub?.items || []}
            onChange={(item) => setData({ selectItem: item })}
            popupIcon={<ExpandMore />}
          />
          <PanelSelection
            label="Category"
            choices={NOTE_CATEGORY}
            inputLabelClass={classes.inputLabel}
            value={NOTE_CATEGORY.find((item) => item.value === Category)}
            onChange={(item) => setData({ Category: item?.value as string })}
            popupIcon={<ExpandMore />}
            width={'100%'}
            height={56}
          />
          <PanelSelection
            height={56}
            label="Request Type"
            inputLabelClass={classes.inputLabel}
            width={'100%'}
            value={REQUEST_TYPE_CHOICE?.find((item1) => item1.value === RequestType)}
            choices={REQUEST_TYPE_CHOICE}
            onChange={(item) => setData({ RequestType: item?.value as string })}
            popupIcon={<ExpandMore />}
          />
        </Box>
        <CustomTextField
          style={{ width: '100%' }}
          label="Note"
          value={Note}
          disabled={false}
          placeholder="Enter here..."
          name="Details"
          onInputChange={(value) => setData({ Note: value })}
          variant="outlined"
          multiline
        />
        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={() => onClose?.(false)}>Cancel</Button>
          <Button onClick={onSaveClick} variant="contained" color="primary" disabled={disabled}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

export default AddNoteModal
