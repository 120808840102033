import dayjs, { Dayjs } from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro'

export const ACTIVATE_COMPANY_LOGO = 'https://s3.amazonaws.com/activate.com/Activate-01.png'
export const ACTIVATE_COMPANY_LOGO_WHITE = 'https://s3.amazonaws.com/activate.com/Activate-02.png'

export const STAR_OPTIONS = [
  { label: 'All Comments', value: 0 },
  { label: '5 Star', value: 5 },
  { label: '4 Star', value: 4 },
  { label: '3 Star', value: 3 },
  { label: '2 Star', value: 2 },
  { label: '1 Star', value: 1 },
]

export const COLOR_MAP: { [key in string]: { color: string } } = {
  Score5: { color: '#4BCF5D' },
  Score4: { color: '#FEE934' },
  Score3: { color: '#F8C636' },
  Score2: { color: '#FF8000' },
  Score1: { color: '#E30613' },
}

export const PERIOD_OPTIONS: IOption[] = [
  { label: 'Today', value: 'Today' },
  { label: 'WTD', value: 'WTD' },
  { label: 'MTD', value: 'MTD' },
  { label: 'Last 7 Days', value: '7-Day' },
  { label: 'Last 30 Days', value: '30-Day' },
  { label: 'Last 60 Days', value: '60-Day' },
  { label: 'Last 90 Days', value: '90-Day' },
  { label: 'Last 12 Months', value: '1-Year' },
  { label: 'YTD', value: 'YearToDate' },
]
export const DEFAULT_PERIOD_LAST_YEAR = PERIOD_OPTIONS.find((item) => item.value === '1-Year') as IOption
export const DEFAULT_PERIOD_YEAR_TO_DATE = PERIOD_OPTIONS.find((item) => item.value === 'YearToDate') as IOption
export const DEFAULT_PERIOD_TODAY = PERIOD_OPTIONS.find((item) => item.value === 'Today') as IOption
export const DEFAULT_DATE_RANGE_TODAY: DateRange<Dayjs> = [dayjs().startOf('day'), dayjs().endOf('day')]

export const DEFAULT_DATE_RANGE_WTD: DateRange<Dayjs> = [dayjs().startOf('day'), dayjs().endOf('day')]
export const DEFAULT_DATE_RANGE_LAST_YEAR: DateRange<Dayjs> = [
  dayjs().month(1).day(1).hour(0).minute(0).second(0),
  dayjs().hour(23).minute(59).second(59),
]
export const DEFAULT_DATE_RANGE_YTD: DateRange<Dayjs> = [
  dayjs().add(-1, 'year').day(1).hour(0).minute(0).second(0),
  dayjs().hour(23).minute(59).second(59),
]
export const PERIOD_OPTIONS2 = [{ label: 'YTD', value: 'YearToDate' }]
