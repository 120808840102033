import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useParams } from 'react-router-dom'
import usePuppeteer from '@hooks/usePuppeteer'
import { getRequestById } from '@api'
import { Box, Typography } from '@mui/material'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import CONFIG from '@constant/config'

const ACTION_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Items',
    source: 'Item',
    width: 150,
  },
  {
    label: 'Detail',
    source: 'Detail',
    align: 'left',
  },
]

const useStyles = makeStyles(() => ({
  paper: {
    width: '800px',
    margin: '0 auto',
    backgroundColor: '#FFF',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#466CB5',
    marginBottom: 24,
    textAlign: 'right',
    paddingTop: 24,
  },
  analysis: {
    fontSize: 28,
    color: '#466CB5',
    fontWeight: 'bold',
  },
  analysisDesc: {
    marginTop: 8,
    fontSize: 16,
    color: '#466CB5',
    fontWeight: 'bold',
  },
  summaryTitle: {
    fontSize: 12,
    color: '#466CB5',
    fontWeight: 'bold',
  },
  summaryDesc: {
    marginTop: 12,
    fontSize: 10,
    color: '#211F1F',
  },
  summaryBox: {
    padding: 12,
    marginTop: 12,
    borderRadius: 6,
    display: 'flex',
    backgroundColor: '#F5F5F5',
    flexDirection: 'column',
  },
  logo: {
    height: '40px',
    display: 'block',
  },
  br: {
    height: 1,
    background: '#E8E8E8',
    marginBottom: 24,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#466CB5',
    marginBottom: 24,
  },
  newPage: {
    pageBreakBefore: 'always',
    breakBefore: 'always',
  },
  coverPage: {
    margin: '-24px -24px 0px',
    height: '299.2mm' /* A4 纸张竖着的高度 800px-210mm(793.8px) 297/(793.8/800) mm*/,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(142deg, #4A66AC 7.51%, #23375D 100%)',
    position: 'relative',
  },
  coverPageTitle: {
    fontSize: 52,
    color: '#FFF',
    fontWeight: 'bold',
    padding: '0 16px',
    textAlign: 'center',
  },
  coverPageRound: {
    fontSize: 20,
    color: '#FFF',
    marginTop: 20,
  },
  coverPageLogo: {
    position: 'absolute',
    bottom: 32,
    height: 40,
  },
}))

interface Params {
  rId: string
}

const PdfGoogleReviewReport: React.FC = () => {
  usePuppeteer()
  const classes = useStyles()
  const { rId } = useParams() as unknown as Params
  const [data, setData] = useState<Record<string, any>>()
  const { ResultJSON = {} } = data || {}
  const {
    BuildingName,
    AddressLine1,
    City,
    State,
    Zip,
    LogoFilename,
    StartDateStr,
    Total,
    EndDateStr,
    PropertySummary,
    Average,
  } = ResultJSON || {}
  useEffect(() => {
    if (rId) {
      getRequestById<Record<string, any>>({ rId }).then((res) => {
        setData(res)
      })
    }
  }, [rId])
  if (!data) return null
  return (
    <div className={classes.paper}>
      <div className={classes.coverPage}>
        <Typography className={classes.coverPageTitle}>Student Feedback Analysis</Typography>
        <Typography className={classes.coverPageRound}>{BuildingName?.en}</Typography>
        <img src={CONFIG.logo} alt="logo" className={classes.coverPageLogo} />
      </div>
      {/* <div className={classes.newPage} /> */}

      <div className={classes.title}>
        <Box sx={{ textAlign: 'left' }}>
          <img src={LogoFilename} alt="logo" className={classes.logo} />
          <Typography sx={{ fontSize: '10px', color: '#707070' }}>Powered by Activate</Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: '12px' }}>{BuildingName?.en}</Typography>
          <Typography sx={{ fontSize: '10px' }}>
            {AddressLine1?.en}, {City?.en}, {State?.en}, {Zip}
          </Typography>
        </Box>
      </div>

      <div className={classes.br} />

      <Typography className={classes.analysis}>ACTIVATE-IQ™ ANALYSIS</Typography>
      <Typography className={classes.analysisDesc}>
        This Summary Was Created By Artificial Intelligence Analyzing Hundreds Of Google Ratings.
      </Typography>
      <Box className={classes.summaryBox}>
        <Typography className={classes.summaryTitle}>Property Summary</Typography>
        <Typography className={classes.summaryDesc}>{`The ${
          BuildingName?.en
        } student housing property has accumulated ${Total} reviews from ${StartDateStr}, to ${EndDateStr}, with an average rating of ${Average}. ${
          PropertySummary || ''
        }`}</Typography>
      </Box>
      <div className={classes.br} />
      <Typography className={classes.subTitle}>Category Analysis</Typography>
      <TableList
        config={[
          {
            label: 'Category Analysis',
            source: 'CategoryAnalysis',
            align: 'left',
          },
        ]}
        data={[{ CategoryAnalysis: ResultJSON.CategoryAnalysis }]}
        tableLayout="fixed"
        pdfPortrait
      />
      <div className={classes.br} style={{ marginTop: 24 }} />
      <Typography className={classes.subTitle}>Action Items</Typography>
      <TableList config={ACTION_CONFIG} data={ResultJSON?.ActionItems || []} tableLayout="fixed" pdfPortrait />
    </div>
  )
}

export default PdfGoogleReviewReport
