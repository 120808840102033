import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ReplayIcon from '@mui/icons-material/Replay'
import React, { useCallback, useRef } from 'react'
import AdditionalQuestion from '@pages/survey/components/AdditionalQuestion'
import { ContentSurveyITF, ProductITF, ProductQuestionITF } from '@/types/entity'
import useSetState from '@hooks/useSetState'
import AddtionalSingleQuestion, { AddtionalCheckboxQuestion } from '@pages/survey/components/AddtionalQuestion'
import { saveSurveyBuilder } from '@api'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 36,
    },
    verticalWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    vertical: {
      flex: 1,
    },
  }
})

interface Props {
  data?: ContentSurveyITF
  bId?: string
  gId?: string
  onChange?: (data: ContentSurveyITF) => void
}
const AdditionalSurvey: React.FC<Props> = ({ data, onChange, gId, bId }) => {
  const classes = useStyles()
  const changeRef = useRef<{ setData: (list: ProductQuestionITF[]) => void }>({ setData: () => {} })
  const { VerticalToProducts, ProductQuestions } = data || {}
  const [info, setInfo] = useSetState<{
    Vertical?: string
    Products?: Array<ProductITF>
    ProductOptions?: Array<ProductITF>
    isConfirm?: boolean
  }>({
    Vertical: data?.Vertical,
    Products: data?.Products,
    isConfirm: !!data?.Vertical,
    ProductOptions: data?.AllProducts,
  })
  const { Vertical, Products, ProductOptions, isConfirm } = info
  const handleChangeVertical = (item: string, products: Array<ProductITF>) => {
    setInfo({ Vertical: item, Products: [...(products || [])] })
  }
  const onReselect = () => {
    setInfo({ isConfirm: false })
  }
  const onConfirm = () => {
    saveSurveyBuilder<{ ProductQuestions: Array<ProductQuestionITF> }>({
      GroupId: gId,
      BuildingId: bId,
      Vertical,
      Products,
    }).then((res) => {
      setInfo({ isConfirm: true })
      changeRef.current?.setData?.(res?.ProductQuestions || [])
      onChange?.({ Vertical, Products, ProductQuestions: res?.ProductQuestions || [] } as ContentSurveyITF)
    })
  }
  const handleChangeProduct = (items: Array<ProductITF>) => {
    setInfo({ Products: items })
  }
  const onChangeQuestions = useCallback(
    (items: Array<ProductQuestionITF>) => {
      onChange?.({ ProductQuestions: items || [] } as ContentSurveyITF)
    },
    [onChange]
  )
  if (!isConfirm) {
    return (
      <Box className={classes.container}>
        <AddtionalSingleQuestion
          title="Select a vertical to build the survey."
          options={VerticalToProducts || []}
          data={Vertical}
          handleChange={handleChangeVertical}
        />
        <AddtionalCheckboxQuestion
          noBorder
          title="Select the products to build the survey"
          options={ProductOptions || []}
          data={Products}
          handleChange={handleChangeProduct}
        />
        <Button
          onClick={onConfirm}
          variant="contained"
          disabled={!Vertical || (Products || [])?.length === 0}
          sx={{ alignSelf: 'flex-end', marginBottom: '24px' }}
        >
          confirm
        </Button>
      </Box>
    )
  }
  // @ts-ignore
  return (
    <Box className={classes.container}>
      <Box className={classes.verticalWrap}>
        <Typography className={classes.vertical}>{`vertical > ${Vertical}`}</Typography>
        <Button onClick={onReselect}>
          <ReplayIcon />
          <Typography>Reselect</Typography>
        </Button>
      </Box>
      <AdditionalQuestion
        list={ProductQuestions}
        onChange={onChangeQuestions}
        changeRef={changeRef}
        bId={bId}
        gId={gId}
      />
    </Box>
  )
}
export default React.memo(AdditionalSurvey)
