import qrImg from '@/assets/images/qr-code/feedback/qr.svg'
import loadingWait from '@assets/images/qr-code/pleaseWait.jpg'
import group from '@/assets/images/qr-code/feedback/group.svg'
import iconStops from '@/assets/images/common/icon_stops.svg'
import iconWarn from '@/assets/images/common/icon_warn.svg'
import iconPhoto from '@/assets/images/common/icon_photo.svg'
import iconYellowWarn from '@/assets/images/common/icon_yellow_warn.svg'
import iconClone from '@/assets/images/common/clone.svg'
import iconDelete from '@/assets/images/common/delete.svg'
import deleteGrey from '@/assets/images/common/deleteGrey.svg'
import iconPrintKit from '@/assets/images/common/icon_print_kit.svg'
import iconRoomRental from '@/assets/images/common/icon_room_rental.svg'
import iconDownLoad from '@/assets/images/common/icon_download.svg'
import iconDownLoadBg from '@/assets/images/common/icon_download_bg.svg'
import iconDownLoadBgGray from '@/assets/images/common/icon_download_bg_gray.svg'
import iconImport from '@/assets/images/common/icon_import.svg'
import iconRoundComplete from '@/assets/images/Round/icon_round_complete.svg'
import iconRoundMissed from '@/assets/images/Round/icon_round_missed.svg'
import iconFilter from '@/assets/images/common/icon_filter.svg'
import star from '@/assets/images/common/star.svg'
import back from '@/assets/images/common/back.svg'
import tool from '@/assets/images/common/tool.svg'
import date from '@/assets/images/common/date.svg'
import dateSelect from '@/assets/images/common/date_select.svg'
import event from '@/assets/images/common/event.svg'
import thumbUp from '@/assets/images/common/thumb_up.svg'
import iconImportTwo from '@/assets/images/common/icon_import02.svg'
import accessTimeFilled from '@/assets/images/common/accessTimeFilled.svg'
import checkCircle from '@/assets/images/common/checkCircle.svg'
import visits from '@/assets/images/common/visits.svg'
import iconExpire from '@/assets/images/common/icon_expire.svg'
import iconAed from '@/assets/images/common/icon_aed.svg'
import iconFire from '@/assets/images/common/icon_fire.svg'
import iconError from '@/assets/images/common/icon_error.svg'
import iconWarnBg from '@/assets/images/common/icon_warn_bg.svg'
import iconComplete from '@/assets/images/common/icon_complete.svg'
import success from '@/assets/images/common/success.svg'
import accountInfo from '@/assets/images/common/accountInfo.svg'
import accountName from '@/assets/images/common/accountName.svg'
import activate from '@/assets/images/common/activate.svg'
import analytics from '@/assets/images/common/analytics.svg'
import contacts from '@/assets/images/common/contacts.svg'
import customerFeedback from '@/assets/images/common/customerFeedback.svg'
import customerSurvey from '@/assets/images/common/customerSurvey.svg'
import digitalProof from '@/assets/images/common/digitalProof.svg'
import installation from '@/assets/images/common/installation.svg'
import library from '@/assets/images/common/library.svg'
import portfolioAnalytics from '@/assets/images/common/portfolioAnalytics.svg'
import propertyName from '@/assets/images/common/propertyName.svg'
import printManagement from '@/assets/images/common/printManagement.svg'
import properties from '@/assets/images/common/propertiese.svg'
import propertyInfo from '@/assets/images/common/propertyInfo.svg'
import scans from '@/assets/images/common/scans.svg'
import extensionLadder from '@/assets/images/common/extensionLadder.svg'
import stepLadder from '@/assets/images/common/stepLadder.svg'
import roundTotal from '@/assets/images/common/roundTotal.svg'
import location from '@/assets/images/common/location.svg'
import qrName from '@/assets/images/common/qrName.svg'
import pmTotal from '@/assets/images/common/pmTotal.svg'
import marketing from '@/assets/images/common/marketing.svg'
import measurement from '@/assets/images/common/measurement.svg'
import masterLibrary from '@/assets/images/common/masterLibrary.svg'
import surveyLibrary from '@/assets/images/common/surveyLibrary.svg'
import reportBuilder from '@/assets/images/common/reportBuilder.svg'
import clone from '@/assets/images/common/clone2.svg'
import iconStateEmpty from '@/assets/images/common/iconStateEmpty.svg'
import iconStateNever from '@/assets/images/common/iconStateNever.svg'
import iconResident from '@/assets/images/common/iconResident.svg'
import iconSolution from '@/assets/images/common/iconSolution.svg'
import iconIntegrations from '@/assets/images/common/iconIntegrations.svg'
import iconPages from '@/assets/images/common/iconPages.svg'
import iconFunctions from '@/assets/images/common/iconFunctions.svg'
import download from '@/assets/images/common/download.svg'
import subscribe from '@/assets/images/common/subscribe.svg'
import iconLink from '@/assets/images/common/iconLink.svg'
import sms from '@/assets/images/common/sms.svg'
import legal from '@/assets/images/common/legal.svg'
import zip from '@/assets/images/common/zip.svg'
import iconVisit from '@/assets/images/common/visit.svg'
import iconHourse from '@/assets/images/common/hourse.svg'
import iconReport from '@/assets/images/common/report.svg'

const Round = {
  iconRoundComplete,
  iconRoundMissed,
  roundTotal,
}
const Menu = {
  accountInfo,
  accountName,
  activate,
  analytics,
  contacts,
  customerFeedback,
  customerSurvey,
  digitalProof,
  installation,
  library,
  portfolioAnalytics,
  propertyName,
  printManagement,
  properties,
  propertyInfo,
  scans,
  marketing,
  masterLibrary,
  surveyLibrary,
  reportBuilder,
  legal,
}
const Images = {
  back,
  star,
  tool,
  event,
  qrImg,
  loadingWait,
  group,
  iconStops,
  iconWarn,
  measurement,
  iconPhoto,
  iconYellowWarn,
  iconClone,
  iconDelete,
  deleteGrey,
  iconPrintKit,
  iconRoomRental,
  iconDownLoad,
  iconImport,
  Round,
  date,
  dateSelect,
  iconFilter,
  thumbUp,
  iconImportTwo,
  accessTimeFilled,
  checkCircle,
  iconDownLoadBg,
  visits,
  iconDownLoadBgGray,
  iconExpire,
  iconAed,
  iconFire,
  iconError,
  iconWarnBg,
  iconComplete,
  success,
  Menu,
  extensionLadder,
  stepLadder,
  location,
  qrName,
  pmTotal,
  iconStateNever,
  iconStateEmpty,
  clone,
  iconResident,
  iconSolution,
  iconIntegrations,
  iconPages,
  iconFunctions,
  download,
  subscribe,
  iconLink,
  sms,
  zip,
  iconVisit,
  iconHourse,
  iconReport,
}
export default Images
