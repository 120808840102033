import {
  DateValidationError,
  DesktopDatePicker,
  MobileDatePickerProps,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers'
import React, { useCallback, useState } from 'react'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import Images from '@assets/images'

const useStyles = makeStyles(() => {
  return {
    date: {
      marginTop: 20,
    },
    pointer: {
      color: '#211F1F',
      cursor: 'pointer',
      ' &.Mui-disabled': {
        textFillColor: 'rgba(0, 0, 0, 0.38)',
      },
    },
    label: {
      color: '#707070',
      ' &.Mui-disabled': {
        color: '#707070',
      },
    },
    notchedOutline: {
      borderColor: '#BCBCBC !important',
      ' &.Mui-disabled': {
        textFillColor: '#BCBCBC',
      },
      ' &:hover': {
        borderColor: '#1976D2 !important',
      },
    },
    notchedOutlineOpen: {
      borderColor: '#1976D2 !important',
    },
  }
})

type MobileDatePickerComponent = (<TDate>(
  props: MobileDatePickerProps<TDate> & React.RefAttributes<HTMLDivElement> & { icon?: React.ReactNode }
) => JSX.Element) & { propTypes?: any }
const DaySelectCom = <TDate,>(props: MobileDatePickerProps<TDate> & { icon?: React.ReactNode }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { slotProps = {}, icon, className, onChange, label = 'Date Range', ...others } = props
  const onClose = useCallback(() => {
    setOpen(false)
  }, [])
  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const onChangeCall = useCallback(
    (value: TDate | null, context: PickerChangeHandlerContext<DateValidationError>) => {
      setOpen(false)
      if (onChange) {
        onChange(value, context)
      }
    },
    [onChange]
  )
  return (
    <>
      <DesktopDatePicker
        {...others}
        className={classnames(classes.date, className)}
        onClose={onClose}
        onOpen={onOpen}
        onChange={onChangeCall}
        open={open}
        label={label}
        format="MM/dd/yyyy"
        slots={{ openPickerIcon: 'img' }}
        closeOnSelect
        slotProps={{
          ...slotProps,
          // toolbar: { hidden: true },
          // actionBar: {actions: []},
          // desktopPaper: {},
          openPickerIcon: {
            src: open ? Images.dateSelect : Images.date,
          },
          textField: {
            disabled: true,
            InputProps: {
              classes: {
                notchedOutline: open ? classes.notchedOutlineOpen : classes.notchedOutline,
                focused: classes.notchedOutlineOpen,
              },
            },
            inputProps: {
              className: classes.pointer,
            },
            InputLabelProps: {
              className: classes.label,
            },
            onClick: (event) => {
              onOpen()
            },
          },
        }}
      />
    </>
  )
}
const DaySelect = DaySelectCom as MobileDatePickerComponent
export default DaySelect
