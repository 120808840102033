import * as React from 'react'
import { Card, IconButton, TextField, InputAdornment } from '@mui/material'
import { useListContext } from 'react-admin'
import { HighlightOff, Search } from '@mui/icons-material'
import FilterListItem from '@/qrcodes/myqrcode/FilterListItem'
import { PopoverList, useStyles } from '@/qrcodes/myqrcode/Aside'
import { useDebouncedCallback } from 'use-debounce'

const language = 'en'

const Aside = (props) => {
  const {
    filterData = {
      Floor: {},
      Location: {},
      EquipmentType: {},
      MechanicalSpace: {},
      StopType: {},
      MechanicalSystem: {},
    },
    onClose,
  } = props
  const { setFilters, filterValues, displayedFilters } = useListContext()
  const stopType = Object.keys(filterData?.StopType || {})
  const equipmentType = Object.keys(filterData?.EquipmentType)
  const location = Object.keys(filterData?.Location)
  const mechanicalSpace = Object.keys(filterData?.MechanicalSpace)
  const floors = Object.keys(filterData?.Floor)
  const system = Object.keys(filterData?.MechanicalSystem)
  const classes = useStyles()
  const useDebouncedFetchFilterData = useDebouncedCallback((event) => {
    if (event.target) {
      setFilters({ ...filterValues, st: event.target.value }, { ...displayedFilters, st: event.target.value })
    } else {
      const { st: _, ...filters } = filterValues
      const { st: __, ...dfilters } = displayedFilters
      setFilters(filters, dfilters)
    }
  }, 1000)
  return (
    <Card className={classes.root}>
      <IconButton onClick={onClose} classes={{ root: classes.clearBtn }}>
        <HighlightOff className={classes.clear} />
      </IconButton>
      <TextField
        onChange={useDebouncedFetchFilterData}
        size="small"
        placeholder={'Search'}
        className={classes.formName}
        InputProps={{
          classes: { underline: classes.name },
          startAdornment: (
            <InputAdornment position="start">
              <Search htmlColor="#637381" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <PopoverList isEmpty={!stopType?.length} label="Stop Type">
        {stopType?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${item}`}
            count={filterData.StopType[item]}
            key={item}
            keyName="sType"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList isEmpty={!equipmentType?.length} label="Type">
        {equipmentType?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${filterData.EquipmentType[item]?.DisplayText?.[language] || ''}`}
            count={filterData.EquipmentType[item]?.['Count']}
            key={item}
            keyName="type"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList isEmpty={!location?.length} label="Location">
        {location?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${item}`}
            count={filterData.Location[item]}
            key={item}
            keyName="location"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList isEmpty={!mechanicalSpace?.length} label="Mechanical Space">
        {mechanicalSpace?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${item}`}
            count={filterData.MechanicalSpace[item]}
            key={item}
            keyName="space"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList isEmpty={!system?.length} label="System">
        {system?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${item}`}
            count={filterData.MechanicalSystem[item]}
            key={item}
            keyName="system"
            value={item}
          />
        ))}
      </PopoverList>
      <PopoverList isEmpty={!floors?.length} label="Floor">
        {floors?.map((item) => (
          <FilterListItem
            toggle={onClose}
            label={`${item}`}
            count={filterData.Floor[item]}
            key={item}
            keyName="floor"
            value={item}
          />
        ))}
      </PopoverList>
    </Card>
  )
}

export default Aside
