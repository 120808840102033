const s3Host = process.env.REACT_APP_S3_BUCKET_URL
const s3_Bucket = process.env.REACT_APP_S3_BUCKET

const CONFIG = {
  s3Host,
  s3_Bucket,
  apiHost: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST || '' : '',
  esbHost: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ESB_HOST || '' : '',
  iconFolder: s3Host + '/icon/',
  iconSvgFolder: s3Host + '/static/iconsvg/',
  equipmentImportTemplate: `${s3Host}/templates/RoundTemplate/NewEquipmentImportTemplate.xlsx`,
  vendorTemplate: `${s3Host}/templates/Vendor/VendorTemplate20240614.xlsx`,
  contactTemplate: `${s3Host}/templates/Contact/ContactTemplate.xlsx`,
  propertyImportTemplate: `${s3Host}/templates/Property/BuildingImportTemplate20240506.xlsx`,
  inspectionTemplate: `${s3Host}/templates/Inspection/InspectionImport.xlsx`,
  adminHost: process.env.REACT_APP_ADMIN_HOST,
  tenantTemplate: `${s3Host}/templates/Tenant/TenantTemplate.xlsx`,
  residentTemplate: `${s3Host}/templates/Tenant/ResidentTemplate.xlsx`,
  activateLogo: 'https://s3.amazonaws.com/activate.com/Activate-01-logo.png',
  activateTerms:
    'https://activate-dev-env.s3.us-east-2.amazonaws.com/static/resources/Activate+Terms+%26+Conditions.pdf',
  logo: `${process.env.PUBLIC_URL}/activate_logo_white.png`,
  logoSvg: `${process.env.PUBLIC_URL}/activate_logo.svg`,
}
export default CONFIG
