import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadCSV, ExportButton, List, TextFieldProps, TopToolbar, useRecordContext, useRedirect } from 'react-admin'
import { TextField, DateField, RowClickFunction } from 'react-admin'
import { useListContext, usePermissions, useRefresh, useUnselectAll, useListFilterContext, UrlField } from 'react-admin'
import { Box, IconButton, Tooltip, Typography, Button, Switch } from '@mui/material'
import Images from '@assets/images'
import Dialog from '@component/Modal/Dialog'
import {
  downloadEquipmentsZipFile,
  downloadQrCodesPrintOrders,
  getEquipmentFilterInfo,
  updateEquipmentInfo,
} from '@api'
import PopupState, { bindToggle, bindFocus, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { useDebouncedCallback } from 'use-debounce'
import { AddCircle, ClearRounded, Print as PrintIcon, QrCode, Settings } from '@mui/icons-material'
import useSetState from '@hooks/useSetState'
import classnames from 'classnames'
import Aside from './EquipmentFilter'
import EquipmentImport from './EquipmentImport'
import { EquipmentFrom } from '@constant/qrcode'
import useStyles from './styles'
import { RaRecord } from 'ra-core'
import SnackBar from '@component/SnackBar'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import jsonExport from 'jsonexport/dist'
import get from 'lodash/get'
import { formatPhoneNumber } from '@/utils'
import DatagridCustomize from '@component/List/DatagridCustomize'

const COLUMN = [
  { source: 'SignId', label: 'Sign ID', sortable: false },
  { source: 'EquipmentName', label: 'Name', sortBy: 'Name' },
  { source: 'TagTypeDisplayText.en', label: 'Tag Type', sortable: false, emptyText: 'N/A' },
  { source: 'StopType', label: 'Stop Type', sortable: false },
  { source: 'EquipmentTypeDisplayText', label: 'Type', sortable: false },
  { source: 'IsLadder', label: 'Ladder', sortable: false },
  { source: 'Location', label: 'QR Location*', sortable: false },
  { source: 'MechanicalSpace', label: 'Mechanical Space*', sortable: false },
  { source: 'MechanicalSystem', label: 'System', sortable: false },
  { source: 'Floor', label: 'Floor*', sortable: false },
  { source: 'InstallationNote', label: 'Installation Note', sortable: false },
  { source: 'SerialNo', label: 'Sr. No.', sortable: false },
  {
    source: 'ExpirationDate',
    label: 'Expiration Date',
    locales: 'en-US',
    options: { year: 'numeric', month: '2-digit', day: '2-digit' },
    sortable: false,
  },
  { source: 'Manufacturer', label: 'Manufacturer', sortable: false },
  { source: 'ExtinguisherType', label: 'Extinguisher Type', sortable: false },
  { source: 'IntegrationId', label: 'Integration EquipmentID', sortable: false },
  { source: 'IntegrationType', label: 'Integration Type', sortable: false },
  { source: 'PointingUrl', label: 'URL', sortable: false },
]
const csvColumn = [
  {
    label: 'qty',
    source: 'QrTypeDisplayText',
  },
  {
    label: 'Size',
    source: 'Size',
  },
  {
    label: 'Material',
    source: 'Material',
  },
  {
    label: 'PrintTemplate',
    source: 'PrintTemplate',
  },
  {
    label: 'QR-Code - EPS file',
    source: 'ImageUrl',
  },
]
interface Props {
  isSelect?: boolean
  rowClick?: RowClickFunction
  equipmentFrom: EquipmentFrom
  rType: string
  onDataChange?: (selectedIds: string[], data: RaRecord[]) => void
  disabledArr?: string[]
  getQuestions?: boolean
}

const Actions: React.FC<Props> = ({ isSelect, equipmentFrom, rType, disabledArr, getQuestions }) => {
  const classes = useStyles()
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const { permissions: { GlobalManageQrCode } = {} } = usePermissions()
  const unselectAll = useUnselectAll('equipmentLibrary')
  const [filterData, setFilterData] = useState<any | null>(undefined)
  const { filterValues, setFilters, displayedFilters } = useListFilterContext()
  const navigate = useNavigate()
  const { selectedIds = [] } = useListContext()

  const selectedFilters = useMemo(() => {
    return Object.keys(displayedFilters || {}).map((item) => ({
      label: displayedFilters[item],
      value: filterValues[item],
      key: item,
    }))
  }, [filterValues, displayedFilters])
  const removeFilter = (keyName: string) => {
    const { [keyName]: _, ...newFilterValues } = filterValues
    const { [keyName]: __, ...newDisplayedFilters } = displayedFilters
    setFilters(newFilterValues, newDisplayedFilters, false)
  }
  const fetchFilterData = async (filterValues: any) => {
    unselectAll()
    const { sType, type, location, floor, space, system } = filterValues
    const params = {
      gId: selectedGroupId,
      bId: selectedPropertyId,
      rType,
      getQuestions,
      exIds: JSON.stringify(disabledArr),
    }
    sType && Object.assign(params, { sType })
    type && Object.assign(params, { type })
    location && Object.assign(params, { location })
    floor && Object.assign(params, { floor })
    space && Object.assign(params, { space })
    system && Object.assign(params, { system })
    const result = await getEquipmentFilterInfo(params)
    // @ts-ignore
    setFilterData({ ...result })
  }
  const useDebouncedFetchFilterData = useDebouncedCallback((filterValues: any) => {
    fetchFilterData(filterValues)
  }, 500)
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDebouncedFetchFilterData(filterValues)
  }, [filterValues])

  useEffect(() => {
    if (equipmentFrom !== EquipmentFrom.Library) {
      setFilters({}, {}, false)
      return () => {
        setFilters({}, {}, false)
      }
    }
  }, [equipmentFrom])

  const onDelete = () => {
    Dialog.show({
      title: 'Are you sure you want to delete this?',
      agreeText: 'Delete',
      onAgree: () => {
        //todo
        // archiveRounds({ RoundIds: selectedIds })
        //   .then(() => {
        //     refresh()
        //    unselectAll()
        //   })
        //   .catch((e) => {
        //     SnackBar({ msg: e, duration: 3000 })
        //   })
      },
    })
  }
  const onDownLoad = useCallback(() => {
    if (!selectedIds || selectedIds.length < 1) return
    downloadEquipmentsZipFile({ EquipmentIds: selectedIds }).then((res: any) => {
      window.open(res?.ZipFile, '_blank')
    })
  }, [selectedIds])
  const handlePrint = () => {
    navigate('/printProof', { state: { qrType: 'EquipmentInspection' } })
  }
  const handlePrintPo = () => {
    downloadQrCodesPrintOrders({
      GroupId: selectedGroupId,
      BuildingId: selectedPropertyId,
      PrintPO: true,
      QrType: 'EquipmentInspection',
    })
      .then((res: any) => {
        window.open(res?.UploadUrl, '_blank')
      })
      .catch((err) => {
        SnackBar({ msg: err, duration: 3000 })
      })
  }
  const handlePrintCsPo = () => {
    downloadQrCodesPrintOrders({
      GroupId: selectedGroupId,
      BuildingId: selectedPropertyId,
      CustomerPO: true,
      QrType: 'EquipmentInspection',
    })
      .then((res: any) => {
        window.open(res?.UploadUrl, '_blank')
      })
      .catch((err) => {
        SnackBar({ msg: err, duration: 3000 })
      })
  }
  const Filter = (
    <>
      {selectedFilters?.length > 0 && (
        <Box className={classes.clearBox}>
          <Button color="primary" disableRipple className={classes.clear} onClick={() => setFilters({}, {})}>
            Clear All
          </Button>
          {selectedFilters.map((item) => {
            const { label, value, key } = item
            return (
              <Button
                key={label}
                endIcon={<ClearRounded htmlColor="#7F7F7F" />}
                onClick={() => {
                  removeFilter(key)
                }}
                className={classes.clearItem}
              >
                {label}
              </Button>
            )
          })}
        </Box>
      )}
      <PopupState variant="popover" popupId="filterPopoverEquipment">
        {(popupState) => (
          <div>
            <Tooltip title="Filter By">
              <IconButton {...bindToggle(popupState)} {...bindFocus(popupState)}>
                <img src={Images.iconFilter} />
              </IconButton>
            </Tooltip>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              classes={{ paper: classes.hoverPaper }}
            >
              <Aside filterData={filterData} onClose={() => popupState.close()} />
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </>
  )
  const exporter = useCallback((records1) => {
    const records = JSON.parse(JSON.stringify(records1))
    const config = {
      header: [...COLUMN, ...csvColumn].map((item) => item.source),
      rename: [...COLUMN, ...csvColumn].map((item) => item.label),
    }
    const data = records.map((item: { [x: string]: string | number }) => {
      const itemData: any = {}
      config.header.forEach((key) => {
        if (key === 'ExpirationDate') {
          itemData[key] = item[key] ? format(item[key] as number, 'yyyy/MM/dd') : ''
        } else {
          itemData[key] = get(item, key) || ''
        }
      })
      return itemData
    })

    return jsonExport(
      data,
      {
        headers: config.header,
        rename: config.rename,
      },
      (err, csv) => {
        downloadCSV(csv, 'EquipmentList')
      }
    )
  }, [])
  return (
    <TopToolbar className={classnames(classes.toolbar, { [classes.directionRow]: isSelect })}>
      <Typography className={classes.title}>Equipment/Location Library</Typography>
      {isSelect ? (
        Filter
      ) : (
        <Box className={classes.action}>
          {/* {selectedIds.length > 0 && (
            <Box onClick={onDelete} className={classes.actionIcon}>
              <img src={Images.iconDelete} style={{ marginRight: 6 }} />
              Delete
            </Box>
          )} */}
          <Typography style={{ flex: 1 }} />
          <Button onClick={handlePrint} className={classes.button}>
            <PrintIcon
              htmlColor="white"
              style={{ fontSize: 24, padding: 4, marginRight: 8, backgroundColor: '#466CB5', borderRadius: 12 }}
            />
            Print Proof
          </Button>
          <Button onClick={handlePrintPo} className={classes.button}>
            <PrintIcon
              htmlColor="white"
              style={{ fontSize: 24, padding: 4, marginRight: 8, backgroundColor: '#466CB5', borderRadius: 12 }}
            />
            Print PO
          </Button>
          <Button onClick={handlePrintCsPo} className={classes.button}>
            <PrintIcon
              htmlColor="white"
              style={{ fontSize: 24, padding: 4, marginRight: 8, backgroundColor: '#466CB5', borderRadius: 12 }}
            />
            Customer PO
          </Button>
          <ExportButton
            className={classes.button}
            label="Download CSV"
            icon={<img src={Images.iconDownLoadBg} />}
            exporter={exporter}
          />
          {selectedIds.length > 0 && (
            <IconButton onClick={onDownLoad}>
              <Tooltip title="Download Print Kit" placement="top">
                <QrCode color="primary" />
              </Tooltip>
            </IconButton>
          )}
          {Filter}
          <IconButton onClick={() => navigate('/equipmentQuestion')}>
            <Settings color="primary" />
          </IconButton>
        </Box>
      )}
    </TopToolbar>
  )
}
const SwitchFiled = (props: TextFieldProps) => {
  const record = useRecordContext(props)
  const value = record[props.source as string]
  const [checked, setChecked] = useState(!!value as boolean)
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    updateEquipmentInfo({ EquipmentId: record.EquipmentId, IsLadder: !checked })
    setChecked(!checked)
  }
  return <Switch checked={checked} name={'checked'} onChange={onChange} onClick={(e) => e.stopPropagation()} />
}
const Table: React.FC<Props> = ({ rowClick, equipmentFrom, onDataChange }) => {
  const classes = useStyles()
  const { selectedIds = [], data = [] } = useListContext()
  //const column = useMemo(() => EquipmentColumn[equipmentFrom] || COLUMN, [equipmentFrom])
  useEffect(() => {
    if (!data?.length) return
    onDataChange?.(selectedIds, data)
  }, [selectedIds, data])
  return (
    <DatagridCustomize rowClick={rowClick}>
      {COLUMN.map((record, index) => {
        const { source } = record
        if (source === 'ExpirationDate') {
          // @ts-ignore
          return <DateField key={index} {...record} />
        }
        if (source === 'IsLadder') {
          // @ts-ignore
          return <SwitchFiled key={index} {...record} />
        }
        if (source === 'PointingUrl') {
          return <UrlField key={index} {...record} target="_blank" rel="noopener noreferrer" />
        }
        return (
          <TextField
            key={index}
            {...record}
            cellClassName={classnames({
              [classes.nameWidth]: record.source === 'EquipmentName',
            })}
          />
        )
      })}
    </DatagridCustomize>
  )
}

interface EquipmentListProps {
  onDataChange?: (selectedIds: string[], data: RaRecord[]) => void
  isSelect?: boolean
  equipmentFrom: EquipmentFrom
  disabledArr?: string[]
}
const EquipmentList = (props: EquipmentListProps) => {
  const { isSelect = false, equipmentFrom = EquipmentFrom.Library, disabledArr = [], onDataChange } = props
  const classes = useStyles({ isSelect })
  const redirect = useRedirect()
  const refresh = useRefresh()
  const { selectedGroupId: gId, selectedPropertyId: bId } = useSelector((state) => state.profile)
  const [{ showImport }, setState] = useSetState({ showImport: false })
  const onAdd = useCallback(() => redirect(`/qrcode/${gId}/${bId}`, '', undefined, undefined, { type: 'Round' }), [bId])
  const rowClick: RowClickFunction = useCallback(
    (id, basePath, record) => {
      if (isSelect) return ''
      const { QrType, QrCodeId, BuildingId } = record
      redirect(`/qrcode/${gId}/${BuildingId}/${QrCodeId}`, '', undefined, undefined, { type: 'Round' })
      return false
    },
    [gId, isSelect, redirect]
  )

  const { rType, getQuestions } = useMemo(() => {
    if (equipmentFrom === EquipmentFrom.Library) return { rType: '', getQuestions: false }
    return { rType: '', getQuestions: true }
  }, [equipmentFrom])
  const actionProps = useMemo(
    () => ({ isSelect, rowClick, equipmentFrom, rType, disabledArr, getQuestions }),
    [isSelect, rowClick, equipmentFrom, rType, disabledArr, getQuestions]
  )
  const afterImport = () => refresh()

  const AddCom = (
    <>
      {!isSelect && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button className={classes.add} onClick={onAdd}>
            <AddCircle style={{ marginRight: 8 }} />
            Add New Equipment/Location
          </Button>

          <Button className={classes.add} onClick={() => setState({ showImport: true })}>
            <img src={Images.iconImport} style={{ marginRight: 6 }} />
            Bulk Import Equipment
          </Button>
          {showImport && <EquipmentImport onClose={() => setState({ showImport: false })} onOk={afterImport} />}
        </Box>
      )}
    </>
  )

  return (
    <List
      {...props}
      disableSyncWithLocation
      empty={AddCom}
      filter={{ gId, bId, rType, exIds: disabledArr, getQuestions }}
      perPage={25}
      actions={<Actions {...actionProps} />}
      sort={{ field: 'CreatedDate', order: 'DESC' }}
    >
      <>
        <Table {...actionProps} onDataChange={onDataChange} />
        {AddCom}
      </>
    </List>
  )
}

export default EquipmentList
