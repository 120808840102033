import React, { useEffect, useMemo } from 'react'
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from 'react-beautiful-dnd'
import { Backdrop, Box, Checkbox, IconButton, Typography, InputAdornment, Modal } from '@mui/material'
import { AccessTime, AddCircle, CameraAlt, Close, DeleteOutline, Reorder, Event } from '@mui/icons-material'
import { reorder } from '@utils/helpers'
import { makeStyles } from '@mui/styles'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import CustomTextField from '@component/Form/CustomTextField'
import FieldType, { FieldItem } from '@utils/FieldType'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import useSetState from '@hooks/useSetState'
import SelectCom from '@component/Form/Form/SelectCom'
import UploadFile from '@component/Form/UploadFile'
import DragUploadFile from '@component/Form/DragUploadFile'

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  userSelect: 'none',
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    backdropMain: {
      width: 500,
      borderRadius: 12,
      backgroundColor: '#fff',
      alignSelf: 'flex-end',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    backdropTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(0,0,0,0.23)',
      padding: 16,
      paddingBottom: 8,
      marginBottom: 8,
    },
    option: {
      padding: '0 16px',
      marginBottom: 16,
      color: '#414141',
    },
    item: {
      display: 'flex',
      marginTop: 20,
    },
    itemText: {
      flex: 1,
    },
    delete: {
      height: 54,
    },
    add: {
      justifyContent: 'flex-start',
      color: '#386BBF',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginTop: 16,
    },
    cameraAlt: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 110,
      border: '1px dashed rgba(0,0,0,0.54)',
      borderRadius: 10,
      color: 'rgba(0,0,0,0.54)',
    },
  }
})

export type FieldTypeArrITF = typeof FieldTypeArr
interface Props {
  language: LanguageType
  data?: FieldItem[]
  disabled?: boolean
  disabledEdit?: boolean
  hiddenAddBtn?: boolean
  onChangeField?: (fields: FieldItem[]) => void
  onlyMoveArr?: string[]
  addText?: string
  fieldTypeArr?: FieldTypeArrITF
  gId?: string
  bId?: string
}

const FieldTypeArr = [
  { label: 'Date', value: FieldType.Date },
  { label: 'Time Range', value: FieldType.Duration },
  { label: 'Time (No Range)', value: FieldType.TimeString },
  { label: 'Text', value: FieldType.Text },
  { label: 'Checklist or Yes/No', value: FieldType.SingleCheckBox },
  { label: 'Multiple Choice', value: FieldType.CheckBox },
  { label: 'Radio Button', value: FieldType.SingleChoice },
  { label: 'Take a photo', value: FieldType.UploadImage },
  { label: 'Digital Signature', value: FieldType.Signature },
]

const DragFormPanel: React.FC<Props> = ({
  language,
  data = [],
  onChangeField,
  disabled = false,
  onlyMoveArr = [],
  disabledEdit = false,
  hiddenAddBtn = false,
  addText = 'Add New',
  fieldTypeArr,
  gId,
  bId,
}) => {
  const classes = useStyles()

  const [{ Fields, open }, setState] = useSetState({
    Fields: (data || []) as FieldItem[],
    open: false,
  })
  const onItemChange = (value: string, name: string, index: number, choiceIndex?: number, isStr?: boolean) => {
    if (name === 'Choices') {
      setState(({ Fields }) => {
        Fields[index][name][choiceIndex as number] = {
          Label: `Label-${choiceIndex}`,
          Text: {
            en: value,
            es: value,
          },
          Value: value,
        }
        const nextFields = [...Fields]
        onChangeField?.(nextFields)
        return { Fields: nextFields }
      })
      return
    }
    if (name === 'add') {
      setState(({ Fields }) => {
        const len = Fields[index]?.Choices.length
        // @ts-ignore
        Fields[index]?.Choices.push({
          Label: `Label${len}`,
          Text: {
            en: value,
            es: value,
          },
          Value: value,
        })
        const nextFields = [...Fields]
        onChangeField?.(nextFields)
        return { Fields: nextFields }
      })
      return
    }
    if (name === 'delete') {
      setState(({ Fields }) => {
        Fields[index].Choices.splice(choiceIndex as number, 1)
        Fields[index].Choices.forEach((item, index) => (item.Label = `Label-${index}`))
        const nextFields = [...Fields]
        onChangeField?.(nextFields)
        return { Fields: nextFields }
      })
      return
    }
    setState(({ Fields }) => {
      // @ts-ignore
      Fields[index][name] = isStr ? value : { en: value, es: value }
      const nextFields = [...Fields]
      onChangeField?.(nextFields)
      return { Fields: nextFields }
    })
  }

  const itemCom = useMemo(
    () => ({
      Date: (item?: FieldItem, index?: number) => (
        <MobileDatePicker
          label={item?.FieldText?.[language] || 'Date'}
          format="MM/dd/yyyy"
          disabled
          value={null}
          sx={{ width: '100%' }}
          onChange={() => {}}
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Event />
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      ),
      Duration: (item: FieldItem) => (
        <MobileTimePicker
          label={item.FieldText?.[language] || 'Time Range'}
          disabled
          value={null}
          sx={{ width: '100%' }}
          onChange={() => {}}
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <AccessTime />
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      ),
      TimeString: (item: FieldItem) => (
        <MobileTimePicker
          label={item.FieldText?.[language] || 'Time (No Range)'}
          disabled
          value={null}
          sx={{ width: '100%' }}
          onChange={() => {}}
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <AccessTime />
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      ),
      Text: (item: FieldItem, index: number) => {
        const itemDisabled = disabledEdit || disabled || onlyMoveArr.includes(item?.Code)
        return (
          <CustomTextField
            label={item.FieldText?.[language] || 'Field name'}
            value={itemDisabled ? '' : item.FieldText?.[language]}
            disabled={itemDisabled}
            labelDisabled={itemDisabled}
            name="FieldText"
            fullWidth
            noMargin
            onInputChange={(value, name) => onItemChange(value, name, index)}
            variant="outlined"
          />
        )
      },
      TextBox: (item: FieldItem, index: number) => {
        const itemDisabled = disabledEdit || disabled || onlyMoveArr.includes(item?.Code)
        return (
          <CustomTextField
            label={item.FieldText?.[language] || 'Field name'}
            value={itemDisabled ? '' : item.FieldText?.[language]}
            disabled={itemDisabled}
            labelDisabled={itemDisabled}
            name="FieldText"
            fullWidth
            noMargin
            onInputChange={(value, name) => onItemChange(value, name, index)}
            variant="outlined"
          />
        )
      },
      SingleCheckBox: (item: FieldItem, index: number) => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <CustomTextField
            label=""
            value={item.FieldText?.[language]}
            disabled={disabledEdit || disabled || onlyMoveArr.includes(item?.Code)}
            name="FieldText"
            fullWidth
            noMargin
            onInputChange={(value, name) => onItemChange(value, name, index)}
            variant="outlined"
          />
          <Checkbox disabled style={{ marginLeft: 8 }} checked={item.ValueText === 'true'} />
        </Box>
      ),
      CheckBox: (item: FieldItem, index: number) => (
        <SelectCom
          item={item}
          index={index}
          disabled={disabled || disabledEdit || onlyMoveArr.includes(item?.Code)}
          onItemChange={onItemChange}
          language={language}
        />
      ),
      SingleChoice: (item: FieldItem, index: number) => (
        <SelectCom
          item={item}
          index={index}
          disabled={disabled || disabledEdit || onlyMoveArr.includes(item?.Code)}
          onItemChange={onItemChange}
          language={language}
          isSingleChoice
          hiddenOption={item.FieldType === 'UserId'}
        />
      ),
      DropDownSelection: (item: FieldItem, index: number) => {
        const itemDisabled = disabled || disabledEdit || onlyMoveArr.includes(item?.Code)
        return (
          <SelectCom
            item={item}
            hiddenIcon
            index={index}
            disabled={itemDisabled}
            onItemChange={onItemChange}
            language={language}
          />
        )
      },
      UploadImage: () => (
        <Box className={classes.cameraAlt}>
          <CameraAlt fontSize="large" />
        </Box>
      ),
      DownLoadFile: (item: FieldItem, index: number) => (
        <UploadFile
          // className={classes.upload}
          value={item.ValueText}
          filePath={`DownLoadForm/${gId}/${bId}`}
          onChange={(file) => onItemChange(file, 'ValueText', index, undefined, true)}
          disabledInput
          label="Terms & Conditions"
        />
      ),
      Signature: (item: FieldItem, index: number) => (
        <CustomTextField
          label={item.FieldText?.[language] || 'Field name'}
          disabled
          labelDisabled
          name="FieldText"
          fullWidth
          noMargin
          variant="outlined"
        />
      ),
      UploadFiles: (item: FieldItem, index: number) => <DragUploadFile disabled uploadSuffix="Cover image" />,
    }),
    [disabled, onlyMoveArr, disabledEdit, bId, gId]
  )
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(Fields || [], source.index, destination.index)
    onChangeField?.(newItems)
    setState({ Fields: newItems })
  }
  const onRemove = (index: number) => {
    Fields.splice(index, 1)
    const nextFields = [...Fields]
    onChangeField?.(nextFields)
    setState({ Fields: nextFields })
  }

  const onAdd = (item: FieldItem) => {
    const newItem = JSON.parse(JSON.stringify(item))
    newItem.Code = `${item.FieldStyle}${Date.now()}`
    Fields.push(newItem)
    const nextFields = [...Fields]
    onChangeField?.(nextFields)
    setState({ Fields: nextFields })
  }
  return (
    <>
      <Modal open={open} onClick={() => setState({ open: !open })}>
        <Box className={classes.backdropMain}>
          <Box className={classes.backdropTitle}>
            <Typography>Select Options</Typography>
            <Close />
          </Box>
          {(fieldTypeArr || FieldTypeArr).map((v) => {
            return (
              <Typography key={v.label} className={classes.option} onClick={() => onAdd(v.value as FieldItem)}>
                {v.label}
              </Typography>
            )
          })}
        </Box>
      </Modal>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {Fields.map((item, index: number) => (
                <Draggable key={item?.Code} draggableId={item?.Code} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      // @ts-ignore
                      style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    >
                      {/* @ts-ignore */}
                      {itemCom[item.FieldStyle] && (
                        <Box className={classes.item}>
                          <Box className={classes.itemText}>
                            {/* @ts-ignore */}
                            {itemCom[item.FieldStyle](item, index)}
                          </Box>
                          {!disabled && (
                            <>
                              <IconButton
                                disableRipple
                                className={classes.delete}
                                onClick={() => onRemove(index)}
                                disabled={item.DisabledDelete || onlyMoveArr.includes(item?.Code)}
                              >
                                <DeleteOutline />
                              </IconButton>
                              <div {...draggableProvided.dragHandleProps}>
                                <IconButton className={classes.delete}>
                                  <Reorder />
                                </IconButton>
                              </div>
                            </>
                          )}
                        </Box>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!hiddenAddBtn && !disabled && !disabledEdit && (
        <Box aria-label="add" className={classes.add} onClick={() => setState({ open: true })}>
          <AddCircle color="primary" style={{ marginRight: 10 }} />
          {addText}
        </Box>
      )}
    </>
  )
}

export default DragFormPanel
