import { addDays, format, getTime, parse } from 'date-fns'
import DateFnsAdapter from '@date-io/date-fns'

export const getRecentWeekly = (count = 10) => {
  const today = new Date()
  let thisSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
  const sundays: Record<string, any>[] = []
  for (let i = 0; i < count; i++) {
    const value = { label: '', value: { startDate: 0, endDate: 0 } }
    value.value = {
      startDate: thisSunday.getTime(),
      endDate: addDays(thisSunday, 6).setHours(23, 59, 59).valueOf(),
    }
    value.label = format(value.value.startDate, 'MM/dd/yy') + ' ~ ' + format(value.value.endDate, 'MM/dd/yy')
    sundays.push(value)
    thisSunday = new Date(thisSunday.getFullYear(), thisSunday.getMonth(), thisSunday.getDate() - 7)
  }
  return sundays
}

export function generateTimeOptions() {
  const options: Record<string, any>[] = []
  for (let i = 12; i < 36; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12
    const ampm = i < 24 ? 'AM' : 'PM'
    for (let j = 0; j < 4; j++) {
      const minute = j * 15
      const value = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${ampm}`
      options.push({ label: value, value })
    }
  }
  return options
}

export const isAfter = (time12h1: string, time12h2: string) => {
  if (!time12h1) return true
  const time24h1 = format(parse(time12h1, 'h:mm a', new Date()), 'HH:mm')
  const time24h2 = format(parse(time12h2, 'h:mm a', new Date()), 'HH:mm')
  const currentDate = new Date().toISOString().split('T')[0]
  const dateTimeString1 = `${currentDate} ${time24h1}`
  const dateTimeString2 = `${currentDate} ${time24h2}`
  const timestamp1 = getTime(new Date(dateTimeString1))
  const timestamp2 = getTime(new Date(dateTimeString2))
  return timestamp2 > timestamp1
}
export const canSaveTimeRange = (arr: string[][]) => {
  let canSave = true
  const numberTimeRange: number[][] = arr.map((range) => {
    return range.map((item, i) => {
      const time = item.split(' ')
      const hour = time[0].split(':')[0]
      const minute = time[0].split(':')[1]
      const ampm = time[1].toUpperCase()
      const hourNumber = Number(hour) % 12
      return ampm === 'AM' ? hourNumber * 60 + Number(minute) : (hourNumber + 12) * 60 + Number(minute)
    })
  })

  numberTimeRange.forEach((item, i) => {
    if (item[1] <= item[0]) {
      item[1] = item[1] + 24 * 60
    }
    if (item[1] - item[0] < 60) {
      canSave = false
    }
  })

  if (!canSave) return canSave

  numberTimeRange
    .sort((a, b) => a[0] - b[0])
    .forEach((arr, i) => {
      if (i > 0) {
        let preEnd = numberTimeRange[i - 1][1]
        preEnd = preEnd >= 24 * 60 ? preEnd - 24 * 60 : preEnd
        if (arr[0] !== preEnd) {
          //尾首不重复
          canSave = false
        }
      }
      if (i === 2) {
        const oneStart = numberTimeRange[0][0]
        let lastEnd = arr[1]
        lastEnd = lastEnd >= 24 * 60 ? lastEnd - 24 * 60 : lastEnd
        if (oneStart !== lastEnd) {
          //首尾不重复
          canSave = false
        }
      }
    })

  return canSave
}

export const getDefaultPicker = () => {
  const dateFns = new DateFnsAdapter()
  const text = dateFns.format(new Date(), 'normalDateWithWeekday')
  return text
}

export function generateDurationOption() {
  const options: Record<string, any>[] = []
  for (let i = 0; i < 24; i++) {
    const hour = i
    const hr = hour > 0 ? `${hour} hr` : ''
    for (let j = 0; j < 4; j++) {
      if (i === 0 && j === 0) {
        continue
      }
      const minute = j * 15
      const minStr = minute > 0 ? `${minute} min` : ''
      options.push({ label: `${hr} ${minStr}`, value: i + minute / 60 })
    }
  }
  options.push({ label: '24 hr', value: 24 })
  return options
}

export const formartMonth = (month: number) => {
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthArr[month - 1]
}

export const formatDuration = (seconds: number = 0) => {
  if (seconds >= 3600) {
    return (seconds / 3600).toFixed(2) + ' Hours'
  } else {
    return (seconds / 60).toFixed(2) + ' min'
  }
}
